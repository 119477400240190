import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FCLink from '../fc_link/fc_link';

export default function MagazineAttribution(props) {
  const { config, issueMonth, issueNumber } = props;
  if (
    !issueMonth ||
    !issueNumber ||
    !get(config, 'site', '') ||
    !get(config, 'env', '')
  ) {
    return null;
  }

  const linkToIssue = `https://www.fastcompany.com/magazine/${issueNumber}`;

  return (
    <aside className="magazine-attribution">
      A version of this article appeared in the{' '}
      <FCLink to={linkToIssue} config={config} title={`${issueMonth} issue`}>
        {issueMonth}
      </FCLink>{' '}
      issue of <span className="fastcompany-magazine">Fast Company</span>{' '}
      magazine.
    </aside>
  );
}

MagazineAttribution.defaultProps = {
  issueMonth: '',
  issueNumber: ''
};

MagazineAttribution.propTypes = {
  config: PropTypes.shape({}).isRequired,
  issueMonth: PropTypes.string,
  issueNumber: PropTypes.string
};
