export default {
  'Innovation By Design': [
    {
      display_name: 'winners',
      entries: [
        {
          name: 'New Lab',
          url: '/90547331/new-lab-innovation-by-design-2020'
        },
        {
          name: 'New York Times COVID-19',
          url: '/90547338/new-york-times-covid-19-innovation-by-design-2020'
        },
        {
          name: 'Supernatural',
          url: '/90547343/supernatural-innovation-by-design-2020'
        },
        {
          name: 'New Story',
          url: '/90547346/new-story-innovation-by-design-2020'
        },
        {
          name: 'Scout',
          url: '/90547352/scout-innovation-by-design-2020'
        },
        {
          name: 'Illusory Material',
          url: '/90547349/illusory-material-innovation-by-design-2020'
        },
        {
          name: 'Clove',
          url: '/90547355/clove-innovation-by-design-2020'
        },
        {
          name: 'Blueprint',
          url: '/90547358/blueprint-innovation-by-design-2020'
        },
        {
          name: 'Hong Kong protests',
          url: '/90547361/hong-kong-protests-innovation-by-design-2020'
        },
        {
          name: 'Resolution Medical Lattice Swab',
          url: '/90547364/resolution-medical-innovation-by-design-2020'
        },
        {
          name: 'Bearaby Tree Napper',
          url: '/90547626/bearaby-tree-napper-innovation-by-design-2020'
        },
        {
          name: 'TWA Hotel',
          url: '/90547632/twa-hotel-innovation-by-design-2020'
        },
        {
          name: 'Outlier.org',
          url: '/90547636/outlier-org-innovation-by-design-2020'
        },
        {
          name: 'Sproute',
          url: '/90547639/sproute-innovation-by-design-2020'
        },
        {
          name: 'Yellow School Bus',
          url: '/90547643/yellow-school-bus-innovation-by-design-2020'
        },
        {
          name: 'Unocup',
          url: '/90547647/unocup-innovation-by-design-2020'
        },
        {
          name: 'Logitech G Adaptive Gaming Kit',
          url: '/90547650/logitech-g-adaptive-innovation-by-design-2020'
        },
        {
          name: 'The Truth Is Local Storefront',
          url: '/90547654/truth-is-local-innovation-by-design-2020'
        },
        {
          name: 'CURA',
          url: '/90547662/cura-innovation-by-design-2020'
        },
        {
          name: 'Courtyard Kindergarten',
          url: '/90547849/courtyard-kindergarten-innovation-by-design-2020'
        },
        {
          name: 'WNDR Alpine',
          url: '/90547857/wndr-alpine-innovation-by-design-2020'
        },
        {
          name: 'Dots',
          url: '/90547859/dots-innovation-by-design-2020'
        },
        {
          name: 'Powerhouse BrattÃ¸rkaia',
          url: '/90547864/powerhouse-brattorkaia-innovation-by-innovation-2020'
        },
        {
          name: 'Figma',
          url: '/90547870/figma-innovation-by-design-2020'
        },
        {
          name: 'ChildSafe Harvey E. Najim',
          url: '/90547888/childsafe-harvey-e-najim-innovation-by-design-2020'
        },
        {
          name: 'ZShield Flex',
          url: '/90547891/zshield-flex-innovation-by-design-2020'
        },
        {
          name: 'BadGood App',
          url: '/90553727/badgood-app-innovation-by-design-2020'
        },
        {
          name: 'VanMoof S3 X3',
          url: '/90547894/vanmoof-s3-x3-innovation-by-design-2020'
        },
        {
          name: 'Claro',
          url: '/90547898/claro-innovation-by-design-2020'
        }
      ]
    },
    {
      display_name: 'categories',
      entries: [
        {
          name: 'Design Company of the Year',
          url: '/90547905/company-of-the-year-innovation-by-design-2020'
        },
        {
          name: 'General Excellence',
          url: '/90547927/general-excellence-innovation-by-design-2020'
        },
        {
          name: 'Apps and Games',
          url: '/90547929/apps-and-games-innovation-by-design-2020'
        },
        {
          name: 'Cities',
          url: '/90547932/cities-innovation-by-design-2020'
        },
        {
          name: 'Data Design',
          url: '/90547935/data-design-innovation-by-design-2020'
        },
        {
          name: 'Experimental',
          url: '/90547937/experimental-innovation-by-design-2020'
        },
        {
          name: 'Fashion and Beauty',
          url: '/90547940/fashion-and-beauty-innovation-by-design-2020'
        },
        {
          name: 'Finance',
          url: '/90547942/finance-innovation-by-design-2020'
        },
        {
          name: 'Graphic Design',
          url: '/90547945/graphic-design-innovation-by-design-2020'
        },
        {
          name: 'Health',
          url: '/90547947/health-innovation-by-design-2020'
        },
        {
          name: 'Home',
          url: '/90547950/home-innovation-by-design-2020'
        },
        {
          name: 'Hospitality',
          url: '/90547953/hospitality-innovation-by-design-2020'
        },
        {
          name: 'Learning',
          url: '/90547956/learning-innovation-by-design-2020'
        },
        {
          name: 'Mobility',
          url: '/90547959/mobility-innovation-by-design-2020'
        },
        {
          name: 'Packaging',
          url: '/90547961/packaging-innovation-by-design-2020'
        },
        {
          name: 'Products',
          url: '/90547964/products-innovation-by-design-2020'
        },
        {
          name: 'Retail Environments',
          url: '/90547967/retail-environments-innovation-by-design-2020'
        },
        {
          name: 'Social Good',
          url: '/90547969/social-good-innovation-by-design-2020'
        },
        {
          name: 'Spaces & Places',
          url: '/90547973/spaces-places-innovation-by-design-2020'
        },
        {
          name: 'Sports & Recreation',
          url: '/90547975/sports-recreation-innovation-by-design-2020'
        },
        {
          name: 'Students',
          url: '/90553730/students-innovation-by-design-2020'
        },
        {
          name: 'Sustainability',
          url: '/90553733/sustainability-innovation-by-design-2020'
        },
        {
          name: 'User Experience',
          url: '/90547977/user-experience-innovation-by-design-2020'
        },
        {
          name: 'Wellness',
          url: '/90547979/wellness-innovation-by-design-2020'
        },
        {
          name: 'Workplace',
          url: '/90547982/workplace-innovation-by-design-2020'
        },
        {
          name: 'Best Design Asia-Pacific',
          url: '/90548075/asia-pacific-innovation-by-design-2020'
        },
        {
          name: 'Best Design Europe, Middle East, and Africa',
          url:
            '/90548339/europe-middle-east-and-africa-innovation-by-design-2020'
        },
        {
          name: 'Best Design Latin America',
          url: '/90548342/latin-america-innovation-by-design-2020'
        },
        {
          name: 'Best Design North America',
          url: '/90548346/north-america-innovation-by-design-2020'
        }
      ]
    }
  ],
  'World Changing Ideas': [
    {
      display_name: 'winners',
      entries: [
        {
          name: 'General Excellence',
          url: '/90483273/one-day-you-might-live-in-a-3d-printed-house'
        },
        {
          name: 'Company of the Year',
          url: '/90490850/this-pea-size-device-helps-fix-holes-in-babies-hearts'
        },
        {
          name: 'Advertising',
          url:
            '/90479319/how-planned-parenthood-got-teens-to-start-talking-to-its-sex-ed-chatbot'
        },
        {
          name: 'AI and Data',
          url:
            '/90490474/this-companys-ai-makes-tiny-tweaks-to-building-systems-resulting-in-huge-emissions-reductions'
        },
        {
          name: 'Apps',
          url:
            '/90484157/this-doordash-for-surplus-food-aims-to-cut-waste-and-help-solve-hunger'
        },
        {
          name: 'Art and Design',
          url:
            '/90488841/this-crazy-sculpture-is-made-by-an-mit-engineered-machine-that-3d-prints-biopolymers-to-replace-plastic'
        },
        {
          name: 'Consumer Products',
          url:
            '/90483835/this-company-wants-to-track-every-single-product-in-the-world'
        },
        {
          name: 'Corporate Social Responsibility',
          url:
            '/90477926/gravity-payments-is-expanding-its-70000-minimum-wage-from-seattle-to-idaho'
        },
        {
          name: 'Creativity',
          url:
            '/90481091/this-crowdsourced-database-of-poop-pictures-is-training-ai-to-help-people-with-gut-issues'
        },
        {
          name: 'Developing-World Technology',
          url:
            '/90483605/when-theres-no-optometrist-for-miles-this-kit-can-still-fill-a-prescription'
        },
        {
          name: 'Education',
          url:
            '/90490028/how-guild-education-is-making-continuing-education-a-workplace-perk'
        },
        {
          name: 'Energy',
          url:
            '/90477609/this-startup-uses-mirrors-to-create-solar-powered-1800-degree-heat-for-industrial-factories'
        },
        {
          name: 'Experimental',
          url:
            '/90489709/this-3d-printer-produces-living-cancer-cells-so-scientists-can-test-drugs-on-the-real-thing'
        },
        {
          name: 'Food',
          url:
            '/90480740/this-startup-sells-ugly-produce-to-businessesto-drive-down-prices-and-carbon-emissions'
        },
        {
          name: 'Health and Wellness',
          url:
            '/90483585/this-at-home-medical-test-startup-may-soon-have-approval-for-a-home-test-for-covid-19'
        },
        {
          name: 'Impact Investing',
          url:
            '/90479563/this-online-tool-helps-you-make-sure-your-retirement-investments-reflect-your-values'
        },
        {
          name: 'Media and Entertainment',
          url:
            '/90489392/how-participant-gave-roma-a-fourth-act-thats-still-benefiting-domestic-workers'
        },
        {
          name: 'Politics and Policy',
          url:
            '/90489579/formerly-incarcerated-floridians-won-the-right-to-vote-this-campaign-is-fighting-to-let-them-keep-it'
        },
        {
          name: 'Social Justice',
          url:
            '/90484262/this-alternate-currency-lets-you-earn-rewards-for-your-good-deeds'
        },
        {
          name: 'Spaces, Places, and Cities',
          url:
            '/90486963/cities-that-need-more-housing-could-get-it-by-rethinking-the-idea-of-what-an-apartment-can-be'
        },
        {
          name: 'Students',
          url:
            '/90483611/this-redesigned-rape-kit-aims-to-empower-assault-survivors-and-the-providers-helping-them'
        },
        {
          name: 'Transportation',
          url:
            '/90477446/trucks-drive-thousands-of-miles-with-no-cargo-this-app-is-making-them-more-efficient'
        },
        {
          name: 'Asia-Pacific',
          url:
            '/90481073/can-this-sustainable-cooling-system-replace-environmentally-damaging-ac'
        },
        {
          name: 'Europe, Middle East, Africa',
          url:
            '/90489600/this-pay-as-you-go-solar-fridge-helps-poor-african-families-save-money-and-food'
        },
        {
          name: 'North America',
          url:
            '/90483317/etsy-offsets-the-entire-carbon-footprint-of-its-shipping-and-it-wants-other-retailers-to-do-the-same'
        },
        {
          name: 'Latin America',
          url:
            '/90489998/this-company-is-introducing-plant-based-protein-to-a-meat-loving-nation'
        }
      ]
    },
    {
      display_name: 'finalists',
      entries: [
        {
          name: 'General Excellence',
          url:
            '/90492132/world-changing-ideas-awards-2020-general-excellence-finalists-and-honorable-mentions'
        },
        {
          name: 'Company of the Year',
          url:
            '/90492200/world-changing-ideas-awards-2020-world-changing-company-of-the-year-finalists'
        },
        {
          name: 'Advertising',
          url:
            '/90492130/world-changing-ideas-awards-2020-advertising-finalists-and-honorable-mentions'
        },
        {
          name: 'AI and Data',
          url:
            '/90492176/world-changing-ideas-awards-2020-ai-and-data-finalists-and-honorable-mentions'
        },
        {
          name: 'Apps',
          url:
            '/90492187/world-changing-ideas-awards-2020-apps-finalists-and-honorable-mentions'
        },
        {
          name: 'Art and Design',
          url:
            '/90492155/world-changing-ideas-awards-2020-art-and-design-finalists-and-honorable-mentions'
        },
        {
          name: 'Consumer Products',
          url:
            '/90492180/world-changing-ideas-awards-2020-consumer-products-finalists-and-honorable-mentions'
        },
        {
          name: 'Corporate Social Responsibility',
          url:
            '/90492149/world-changing-ideas-awards-2020-corporate-social-responsibility-finalists-and-honorable-mentions'
        },
        {
          name: 'Creativity',
          url:
            '/90492123/world-changing-ideas-awards-2020-creativity-finalists-and-honorable-mentions'
        },
        {
          name: 'Developing-World Technology',
          url:
            '/90492106/world-changing-ideas-awards-2020-developing-world-technology-finalists-and-honorable-mentions'
        },
        {
          name: 'Education',
          url:
            '/90492126/world-changing-ideas-awards-2020-education-finalists-and-honorable-mentions'
        },
        {
          name: 'Energy',
          url:
            '/90492165/world-changing-ideas-awards-2020-energy-finalists-and-honorable-mentions'
        },
        {
          name: 'Experimental',
          url:
            '/90492195/world-changing-ideas-awards-2020-experimental-finalists-and-honorable-mentions'
        },
        {
          name: 'Food',
          url:
            '/90492190/world-changing-ideas-awards-2020-food-finalists-and-honorable-mentions'
        },
        {
          name: 'Health and Wellness',
          url:
            '/90492160/world-changing-ideas-awards-2020-health-and-wellness-finalists-and-honorable-mentions'
        },
        {
          name: 'Impact Investing',
          url:
            '/90492172/world-changing-ideas-awards-2020-impact-investing-finalists-and-honorable-mentions'
        },
        {
          name: 'Media and Entertainment',
          url:
            '/90492114/world-changing-ideas-awards-2020-media-and-entertainment-finalists-and-honorable-mentions'
        },
        {
          name: 'Politics and Policy',
          url:
            '/90492095/world-changing-ideas-awards-2020-politics-and-policy-finalists-and-honorable-mentions'
        },
        {
          name: 'Social Justice',
          url:
            '/90492120/world-changing-ideas-awards-2020-social-justice-finalists-and-honorable-mentions'
        },
        {
          name: 'Spaces, Places, and Cities',
          url:
            '/90492136/world-changing-ideas-awards-2020-spaces-places-and-cities-finalists-and-honorable-mentions'
        },
        {
          name: 'Students',
          url:
            '/90492112/world-changing-ideas-awards-2020-students-finalists-and-honorable-mentions'
        },
        {
          name: 'Transportation',
          url:
            '/90492152/world-changing-ideas-awards-2020-transportation-finalists-and-honorable-mentions'
        },
        {
          name: 'Asia-Pacific',
          url:
            '/90492198/world-changing-ideas-awards-2020-asia-pacific-finalists-and-honorable-mentions'
        },
        {
          name: 'Europe, Middle East, Africa',
          url:
            '/90492169/world-changing-ideas-awards-2020-europe-middle-east-and-africa-finalists-and-honorable-mentions'
        },
        {
          name: 'North America',
          url:
            '/90492140/world-changing-ideas-awards-2020-north-america-finalists-and-honorable-mentions'
        },
        {
          name: 'Latin America',
          url:
            '/90492183/world-changing-ideas-awards-2020-latin-america-finalists-and-honorable-mentions'
        }
      ]
    }
  ]
};
