import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

/*
 * A HOC that sticks it's children to the bottom of the screen, above the mobile ad unit once they're scrolled into view.
  * https://app.shortcut.com/mansueto/story/64898/fc-mobile-adhesive-video-unit-fixes-dev
*/

const BottomSticky = ({ children, getAdhesiveHeight }) => {
  const containerRef = useRef();
  const [isSticky, setSticky] = useState(false);

  function updateStickyOffset() {
    let mobileAdAdhesiveHeight = 0;
    const root = document.querySelector(':root');

    try {
      mobileAdAdhesiveHeight = getAdhesiveHeight();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error getting mobileAdAdhesiveHeight, setting to 0', error);
    }

    root.style.setProperty(
      '--mobile-adhesive-ad-height',
      `${mobileAdAdhesiveHeight}px`
    );
  }

  function handleVisibility(entries, observer) {
    const [formContainer] = entries;

    if (formContainer.isIntersecting && !isSticky) {
      updateStickyOffset();
      setSticky(true);
      observer.unobserve(containerRef.current);
    }
  }

  useEffect(
    () => {
      if (!containerRef.current) return;

      const observer = new IntersectionObserver(
        entries => handleVisibility(entries, observer),
        {
          root: null,
          rootMargin: '0px 0px -70px 0px',
          threshold: 1
        }
      );

      if (containerRef.current) observer.observe(containerRef.current);

      // eslint-disable-next-line consistent-return
      return () => {
        if (containerRef.current) observer.unobserve(containerRef.current);
      };
    },
    [containerRef]
  );

  return (
    <>
      {isSticky && (
        <div className="bottomSticky__placeholder">
          <div className="bottomSticky__placeholder--inner" />
        </div>
      )}
      <div
        className={`sticky-outer-wrapper${isSticky ? ' active' : ''}`}
        ref={containerRef}
      >
        {children}
      </div>
    </>
  );
};

export default BottomSticky;

BottomSticky.defaultProps = {
  getAdhesiveHeight: () => 0
};

BottomSticky.propTypes = {
  children: PropTypes.element.isRequired,
  getAdhesiveHeight: PropTypes.func
};
