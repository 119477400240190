module.exports = {
  // personalized recommendations widget on post pages
  postPersonalizedRecommendationsEnabled: true,
  // a/b test scrolling module on homepage
  abTestScrollingModuleParsely: false,
  // Native ad powered tiles on article page
  nativePixelContentArticlePage: true,
  // Floating video on header container as you scroll
  floatingVideo: false,
  // Toaster Video
  toasterVideo: false,
  textToShare: true,
  navRedesign: true,
  mic2020: true,
  mic2021staticbar: true,
  isDynamicNativeTileEnabled: true
};
