import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarketoForm from '../../MarketoForm/MarketoForm';

const PluggedinFooter = ({ type, lazy }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div
      className={`newsletter__widget newsletter__widget__${type} widget__state--${
        submitted ? 'submitted' : 'init'
      }`}
    >
      <header>
        <img
          alt="PluggedIn Newsletter logo"
          className="header__logo"
          src="https://assets.fastcompany.com/image/upload/v1680289092/newsletters/pluggedInLogoArticle.svg"
          width={240}
          height={60}
        />
        {!submitted && (
          <div>
            <span className="highlight">
              Sign up for our weekly tech digest.
            </span>
          </div>
        )}
      </header>
      {submitted ? (
        <p className="submission success__message">
          <span className="text-primary">
            Thank you! Look out for your first email!
          </span>
        </p>
      ) : (
        <MarketoForm
          assetId={1195}
          name="inline-newsletter-form"
          onSubmitHandler={setSubmitted}
          lazy={lazy}
        />
      )}
      <footer>
        <a
          rel="noopener noreferrer"
          href="https://www.mansueto.com/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

PluggedinFooter.displayName = 'PluggedinFooter';

PluggedinFooter.propTypes = {
  type: PropTypes.string.isRequired,
  lazy: PropTypes.bool
};

PluggedinFooter.defaultProps = {
  lazy: false
};

export default PluggedinFooter;
