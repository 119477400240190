import React, { Component } from 'react';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import get from 'lodash/get';
import withRouter from 'react-router/withRouter';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FCLink from '../../../components/fc_link/fc_link';

// TODO: Tom to add urls here
const posts = [
  {
    id: 90598114,
    title: 'Advertising',
    url: '/90598114/advertising-most-innovative-companies-2021',
    rank: 1
  },
  {
    id: 90600111,
    title: 'Architecture',
    url: '/90600111/architecture-most-innovative-companies-2021',
    rank: 2
  },
  {
    id: 90600124,
    title: 'Artificial Intelligence',
    url: '/90600124/artificial-intelligence-most-innovative-companies-2021',
    rank: 3
  },
  {
    id: 90600130,
    title: 'Augmented Reality & Virtual Reality',
    url:
      '/90600130/augmented-reality-virtual-reality-most-innovative-companies-2021',
    rank: 4
  },
  {
    id: 90600141,
    title: 'Beauty',
    url: '/90600141/biotech-most-innovative-companies-2021',
    rank: 5
  },
  {
    id: 90600144,
    title: 'Branding',
    url: '/90600144/branding-most-innovative-companies-2021',
    rank: 6
  },
  {
    id: 90600162,
    title: 'Consumer Electronics',
    url: '/90600162/consumer-electronics-most-innovative-companies-2021',
    rank: 7
  },
  {
    id: 90600166,
    title: 'Social Responsibility',
    url:
      '/90600166/corporate-social-responsibility-most-innovative-companies-2021',
    rank: 8
  },
  {
    id: 90600170,
    title: 'Data Science',
    url: '/90600170/data-science-most-innovative-companies-2021',
    rank: 9
  },
  {
    id: 90600173,
    title: 'Design',
    url: '/90600173/design-most-innovative-companies-2021',
    rank: 10
  },
  {
    id: 90600177,
    title: 'Dining',
    url: '/90600177/dining-most-innovative-companies-2021',
    rank: 11
  },
  {
    id: 90600179,
    title: 'Education',
    url: '/90600179/education-most-innovative-companies-2021',
    rank: 12
  },
  {
    id: 90600183,
    title: 'Energy',
    url: '/90600183/energy-most-innovative-companies-2021',
    rank: 13
  },
  {
    id: 90600194,
    title: 'Enterprise',
    url: '/90600194/enterprise-most-innovative-companies-2021',
    rank: 14
  },
  {
    id: 90600198,
    title: 'Film & Television',
    url: '/90600198/film-television-most-innovative-companies-2021',
    rank: 15
  },
  {
    id: 90600205,
    title: 'Finance',
    url: '/90600205/finance-most-innovative-companies-2021',
    rank: 16
  },
  {
    id: 90600210,
    title: 'Food',
    url: '/90600210/food-most-innovative-companies-2021',
    rank: 17
  },
  {
    id: 90600212,
    title: 'Gaming',
    url: '/90600212/gaming-most-innovative-companies-2021',
    rank: 18
  },
  {
    id: 90600223,
    title: 'Health',
    url: '/90600223/health-most-innovative-companies-2021',
    rank: 19
  },
  {
    id: 90600225,
    title: 'Joint Ventures',
    url: '/90600225/joint-ventures-most-innovative-companies-2021',
    rank: 20
  },
  {
    id: 90600228,
    title: 'Live Events',
    url: '/90600228/live-events-most-innovative-companies-2021',
    rank: 21
  },
  {
    id: 90600233,
    title: 'Logistics',
    url: '/90600233/logistics-most-innovative-companies-2021',
    rank: 22
  },
  {
    id: 90600241,
    title: 'Manufacturing',
    url: '/90600241/manufacturing-most-innovative-companies-2021',
    rank: 23
  },
  {
    id: 90600245,
    title: 'Media',
    url: '/90600245/media-most-innovative-companies-2021',
    rank: 24
  },
  {
    id: 90600253,
    title: 'Music',
    url: '/90600253/music-most-innovative-companies-2021',
    rank: 25
  },
  {
    id: 90600258,
    title: 'Not-for-Profit',
    url: '/90600258/not-for-profit-most-innovative-companies-2021',
    rank: 26
  },
  {
    id: 90600263,
    title: 'Retail',
    url: '/90600263/retail-most-innovative-companies-2021',
    rank: 27
  },
  {
    id: 90600286,
    title: 'Robotics',
    url: '/90600286/robotics-most-innovative-companies-2021',
    rank: 28
  },
  {
    id: 90600263,
    title: 'Security',
    url: '/90600289/security-most-innovative-companies-2021',
    rank: 29
  },
  {
    id: 90600295,
    title: 'Small & Mighty',
    url: '/90600295/small-mighty-most-innovative-companies-2021',
    rank: 30
  },
  {
    id: 90600263,
    title: 'Social Good',
    url: '/90600317/social-good-most-innovative-companies-2021',
    rank: 31
  },
  {
    id: 90600321,
    title: 'Social Media',
    url: '/90600321/social-media-most-innovative-companies-2021',
    rank: 32
  },
  {
    id: 90600324,
    title: 'Space',
    url: '/90600324/space-most-innovative-companies-2021',
    rank: 33
  },
  {
    id: 90600263,
    title: 'Sports',
    url: '/90600328/sports-most-innovative-companies-2021',
    rank: 34
  },
  {
    id: 90600331,
    title: 'Style',
    url: '/90600331/style-most-innovative-companies-2021',
    rank: 35
  },
  {
    id: 90600333,
    title: 'Transportation',
    url: '/90600333/transportation-most-innovative-companies-2021',
    rank: 36
  },
  {
    id: 90600337,
    title: 'Travel',
    url: '/90600337/travel-most-innovative-companies-2021',
    rank: 37
  },
  {
    id: 90600341,
    title: 'Urban Development',
    url: '/90600341/urban-development-most-innovative-companies-2021',
    rank: 38
  },
  {
    id: 90600350,
    title: 'Video',
    url: '/930600350/video-most-innovative-companies-2021',
    rank: 39
  },
  {
    id: 90600352,
    title: 'Wellness',
    url: '/90600352/wellness-most-innovative-companies-2021',
    rank: 40
  },
  {
    id: 90600358,
    title: 'Workplace',
    url: '/90600358/workplace-most-innovative-companies-2021',
    rank: 41
  },
  {
    id: 90600337,
    title: 'North America',
    url: '/90600362/north-america-most-innovative-companies-2021',
    rank: 42
  },
  {
    id: 90600367,
    title: 'Latin America',
    url: '/90600367/latin-america-most-innovative-companies-2021',
    rank: 43
  },
  {
    id: 90600369,
    title: 'Europe, Middle East, and Africa',
    url: '/90600369/europe-middle-east-africa-most-innovative-companies-2021',
    rank: 44
  },
  {
    id: 90600337,
    title: 'Asia-Pacific',
    url: '/90600337/travel-most-innovative-companies-2021',
    rank: 45
  }
];

class StaticBar extends Component {
  constructor(props) {
    super(props);
    this.posts = posts;
    const { postId } = this.props;
    this.handleChange = this.handleChange.bind(this);
    const middle = findIndex(posts, { id: postId });
    let previous = posts[middle - 1];
    let next = posts[middle + 1];

    if (middle === 0) {
      previous = false;
    }

    if (middle === posts.length - 1) {
      next = false;
    }

    const selected = find(this.posts, { id: postId });

    this.state = {
      selected,
      previous,
      next
    };
  }

  componentDidMount() {
    this.blurEvent = () => {
      this.select.current.blur();
    };
    document.addEventListener('click', this.blurEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.blurEvent);
  }

  handleChange(event) {
    this.props.nextPage(
      get(
        find(this.posts, { id: parseInt(event.target.value, 10) }),
        'url',
        ''
      ).replace('https://www.fastcompany.com', '')
    );
  }

  render() {
    if (!find(this.posts, { id: this.props.postId })) {
      return null;
    }

    if (!this.state) {
      return null;
    }

    const { selected, previous, next } = this.state;
    const arrows = !this.posts.length;
    return (
      <div className="mic-bar">
        <strong className="mic-bar__title">
          <a href="/most-innovative-companies/2021" alt="">
            THE WORLD’S 50 MOST INNOVATIVE COMPANIES
          </a>
        </strong>
        <div className="mic-bar__pagination">
          {previous ? (
            <FCLink config={this.props.config} to={previous.url}>
              <button
                className={`mic-bar__button mic-bar__button--back mic-bar__button--arrow ${
                  arrows ? 'mic-bar__button--arrow' : ''
                }`}
              >
                {get(previous, 'title')}
              </button>
            </FCLink>
          ) : null}
          <div className="mic-bar__select">
            <select onChange={this.handleChange}>
              {this.posts.map(item => (
                <option
                  selected={get(item, 'rank') === get(selected, 'rank')}
                  value={get(item, 'id')}
                >
                  {get(item, 'title')}
                </option>
              ))}
            </select>
            <div className="mic-bar__selected">{get(selected, 'title')}</div>
          </div>
          {next ? (
            <FCLink config={this.props.config} to={next.url}>
              <button
                className={`mic-bar__button mic-bar__button--forward mic-bar__button--arrow ${
                  arrows ? 'mic-bar__button--arrow' : ''
                }`}
              >
                {get(next, 'title')}
              </button>
            </FCLink>
          ) : null}
        </div>
      </div>
    );
  }
}

StaticBar.defaultProps = {
  config: {},
  postId: '',
  nextPage() {}
};

StaticBar.propTypes = {
  config: PropTypes.shape({}),
  postId: PropTypes.string,
  nextPage: PropTypes.func
};

function mapStateToProps(state = {}, ownProps) {
  return {
    config: state.config || {},
    postId: ownProps.postId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nextPage(link) {
      dispatch(push(link));
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StaticBar)
);
