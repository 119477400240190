import React from 'react';
import PropTypes from 'prop-types';

const Overlay = props => {
  const {
    title = '',
    director = '',
    credit = '',
    image = '',
    content = '',
    handleOnClose = () => null,
    setCloudinaryOptimizations
  } = props;
  return (
    <div className="accordion_overlay">
      <div className="container">
        <div className="image">
          <img src={setCloudinaryOptimizations(image, 450)} alt={title} />
        </div>
        <div className="content">
          <h3>{title}</h3>
          <p className="director">
            <strong>{director}</strong>
          </p>
          <p className="credit">
            <strong>{credit}</strong>
          </p>
          <p className="details">{content}</p>
        </div>
      </div>
      <div className="close" onClick={() => handleOnClose()} />
    </div>
  );
};

Overlay.propTypes = {
  title: PropTypes.string.isRequired,
  director: PropTypes.string.isRequired,
  credit: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  setCloudinaryOptimizations: PropTypes.func.isRequired
};

export default Overlay;
