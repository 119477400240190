import React from 'react';

const BlueConicPremiumPaywall = () => (
  <div className="paywall">
    <div className="paywall__gradient" />
    <div id="premiumPaywallContainer" />
  </div>
);

export default BlueConicPremiumPaywall;
