import React, { Component } from 'react';
import { canUseDOM } from 'exenv';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie';
import uniqBy from 'lodash/uniqBy';
import FCLink from '../fc_link/fc_link';
import { trainParselyProfile } from '../../../client/utils/third_party_trackers/parsely';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import generateTagData from '../../../utils/advertorial_utils';

class PersonalizedRecommendations extends Component {
  constructor() {
    super();
    this.fetchRecommendations = this.fetchRecommendations.bind(this);
    this.renderNativePixelRecommendation = this.renderNativePixelRecommendation.bind(
      this
    );

    this.state = {
      personalizedRecommendations: [],
      fetchedNativeAdPost: null,
      variation: null
    };
  }

  componentWillMount() {
    if (canUseDOM) {
      this.setState(
        {
          variation: Math.floor(Math.random() * 2) + 1
        },
        () => {
          this.fetchRecommendations();
        }
      );
      // track a widget viewed event to better gauge ctr %
      trackGAEvent({
        eventCategory: 'related',
        eventAction: 'viewed',
        eventLabel: 'article-page'
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.personalizedRecommendations.length) {
      return;
    }

    const postNativeAd = get(nextProps, 'postNativeAd');

    if (get(postNativeAd, 'length')) {
      // Must sanitize postNativeAd data to conform with what this widget wants
      const sanitizedPostNativeAdPost = [];
      // Get the first post only
      const postNativeAdPost = postNativeAd[0];

      const tagProcessed = generateTagData(postNativeAdPost.tags);
      const postModified = {};
      postModified.title = postNativeAdPost.title;
      postModified.url = postNativeAdPost.link;
      postModified.sponsor = get(tagProcessed, 'advertiser');

      if (postModified.sponsor) {
        postModified.sponsor = postModified.sponsor.toLowerCase();
      }

      sanitizedPostNativeAdPost.push(postModified);

      this.setState({
        fetchedNativeAdPost: sanitizedPostNativeAdPost
      });
    }
  }

  shouldComponentUpdate() {
    return true;
    // If we don't do this, this widget will render every time inf scroll happens
    // return (
    //   this.state.personalizedRecommendations.length === 0 ||
    //   this.state.fetchedNativeAdPost
    // );
  }

  fetchRecommendations() {
    const uuid = get(window, 'PARSELY.config.uuid');
    const { config, link } = this.props;
    const site = get(config, 'site');
    const apiEndpoint = get(config, 'apiEndpoint');
    const apiProtocol = get(config, 'protocol');

    // https://www.parse.ly/help/api/time/
    // https://trello.com/c/YAM79Xv5/1740-add-a-4th-you-may-also-like-link-video-permalinks
    const pubDateStart = '35w';
    const pubDateEnd = '1m';

    let parselyURL = `https://api.parsely.com/v2/related?apikey=${site}.com&url=${link}&uuid=${uuid}&pub_date_start=${pubDateStart}&pub_date_end=${pubDateEnd}`;

    if (this.state.variation === 1) {
      parselyURL = `${apiProtocol}${apiEndpoint}/api/v3/scrolling-module-posts/fastcompany?total=20&abgroup=2`;
    }

    const currUrl = get(window, 'location.pathname', '');
    const isPreview =
      currUrl.includes('/preview/') ||
      currUrl.includes('/pvw/') ||
      currUrl.includes('THIS-IS-A-PREVIEW-LINK');

    if (!isPreview) {
      // https://www.parse.ly/help/api/recommendations/#personalized
      Promise.all([
        axios.get(`${apiProtocol}${apiEndpoint}/api/v1/jw-playlist/Y4a4AwUd/1`),
        axios.get(parselyURL)
      ]).then(res => {
        if (get(res, 'length')) {
          const randomSeed = Math.floor(Math.random() * 9) + 0;
          const videoPlaylistData = get(res, '[0].data.playlist', []);

          // Select a random video from list, and append it to 4th item in result
          const randomVideo = videoPlaylistData[randomSeed];
          const randomVideoNormalized = {
            title: `Video: ${get(randomVideo, 'title')}`,
            section: 'video',
            url: get(randomVideo, 'link')
          };

          let parselyData = get(res, '[1].data.data', []);

          if (this.state.variation === 1) {
            parselyData = get(res, '[1].data.posts', []);
          }

          if (get(parselyData, 'length')) {
            parselyData.splice(3, 0, randomVideoNormalized);
          }

          this.setState({
            personalizedRecommendations: this.sanitizeRecommendations(
              parselyData
            )
          });
        }
      });
    }
  }

  sanitizeRecommendations(recommendations) {
    // 1. Remove titles with Fast Company or Fastcodesign
    // 2. Remove any advertorial posts from non-advertorial posts
    // 3. Dedup any title
    const abGroupCookie = Cookies.get('abgroup');
    let defaultABGroup = 0;
    let MAX_STORIES = 4;

    if (abGroupCookie) {
      defaultABGroup = parseInt(abGroupCookie, 10);
    }
    // Commenting out bc of story ch48322
    // if (defaultABGroup >= 1 && defaultABGroup <= 2) {
    //   MAX_STORIES = 0;
    // }
    if (defaultABGroup >= 3 && defaultABGroup <= 20) {
      MAX_STORIES = 2;
    }

    const { isAdvertorial } = this.props;

    const sanitizedRecommendations = uniqBy(recommendations, recommendation => {
      const title = get(recommendation, 'title', '');
      return title.toLowerCase();
    }).filter(recommendation => {
      const recommendationNormalized = recommendation;

      if (recommendationNormalized.link) {
        recommendationNormalized.url = recommendationNormalized.link;
      }

      const hasNoStageInUrl = !recommendationNormalized.url.includes('stage');
      const hasNoLocalInUrl = !recommendationNormalized.url.includes('local');
      const noDupWithCurrentPost = !document.location.href.includes(
        recommendationNormalized.id
      );

      if (isAdvertorial) {
        return (
          recommendationNormalized.title !== 'Fast Company' &&
          recommendationNormalized.title !== 'Fastcodesign' &&
          hasNoStageInUrl &&
          hasNoLocalInUrl
        );
      }

      return (
        recommendationNormalized.title !== 'Fast Company' &&
        recommendationNormalized.title !== 'Fastcodesign' &&
        hasNoStageInUrl &&
        hasNoLocalInUrl &&
        noDupWithCurrentPost
      );
    });

    if (get(sanitizedRecommendations, 'length') > MAX_STORIES) {
      return sanitizedRecommendations.slice(0, MAX_STORIES);
    }

    return sanitizedRecommendations;
  }

  renderNativePixelRecommendation() {
    const { config } = this.props;

    if (!get(this.state, 'fetchedNativeAdPost.length')) {
      return '';
    }

    return this.state.fetchedNativeAdPost.map((link, index) => (
      <li
        className="post-personalized-recommendations__item"
        key={index}
        onClick={() => {
          trackGAEvent({
            eventCategory: 'related',
            eventAction: 'clicked',
            eventLabel:
              'algorithm=nativepixel,location=article-bottom,position=5'
          });
          trainParselyProfile(link.url);
        }}
      >
        {link.sponsor ? (
          <div className="post-personalized-recommendations__sponsored-eyebrow">{`Presented by ${
            link.sponsor
          }`}</div>
        ) : (
          ''
        )}
        <FCLink config={config} title={link.title} to={link.url}>
          {link.title}
        </FCLink>
      </li>
    ));
  }

  render() {
    const { personalizedRecommendations } = this.state;
    const { config } = this.props;
    // const { post } = this.props;

    if (!get(personalizedRecommendations, 'length')) {
      return '';
    }

    return (
      <section className="post-personalized-recommendations">
        <h3 className="post-personalized-recommendations__header">
          You Might Also Like:
        </h3>
        <ul className="post-personalized-recommendations__list">
          {this.sanitizeRecommendations(personalizedRecommendations).map(
            (link, index) => (
              <li
                className="post-personalized-recommendations__item"
                key={index}
                onClick={() => {
                  let position = index;
                  position += 1;
                  trackGAEvent({
                    eventCategory: 'related',
                    eventAction: 'clicked',
                    eventLabel: `algorithm=${
                      this.state.variation === 1 ? 'parsely-manual' : 'parsely'
                    },location=article-bottom,position=${position}`
                  });
                  trainParselyProfile(link.url);
                }}
              >
                <FCLink config={config} title={link.title} to={link.url}>
                  {link.title}
                </FCLink>
              </li>
            )
          )}
          {this.renderNativePixelRecommendation()}
        </ul>
      </section>
    );
  }
}

PersonalizedRecommendations.propTypes = {
  // post: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({}).isRequired,
  link: PropTypes.string.isRequired,
  isAdvertorial: PropTypes.bool.isRequired
};

function mapStateToProps(state = {}) {
  const recommendationNativeAdData = get(
    state,
    'postNativeAd.data.recommendations.posts'
  );

  if (recommendationNativeAdData) {
    return {
      postNativeAd: recommendationNativeAdData
    };
  }

  return {
    postNativeAd: []
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(
  PersonalizedRecommendations
);
