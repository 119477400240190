/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
const componentMap = (props, components) => {
  const fallback = components.default || null;
  const matchedKey = Object.keys(props).find(key => {
    if (components[key]) return key;
  });
  return components[matchedKey] || fallback;
};

export default componentMap;
