import React from 'react';

export function hasSpotify(postId) {
  switch (postId) {
    case 90686493:
      return true;

    case 90659174:
      return true;

    case 90675423:
      return true;

    case 90661935:
      return true;

    case 90674883:
      return true;

    case 90677509:
      return true;

    case 90678282:
      return true;

    case 90686730:
      return true;

    default:
      return false;
  }
}

export function handleSpotifyEmbed(postId) {
  switch (postId) {
    case 90686493:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/5JVqeHYyXn0obWMqHG4nyf"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    case 90659174:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/2GLSZUqE0pCBOWQzjyYMLl"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    case 90675423:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/795EHRv8SwTv6pTXU1DeTD"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    case 90661935:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/3fNVCs232DjK6bX68Xy7uc"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    case 90674883:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/2X3rCtql7FMS4xlG6vuM1I"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    case 90677509:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/4LvsYhRt1tdht8Dvm30j7p"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    case 90678282:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/3D8bG4ION36TGQGZcD1vFA"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    case 90686730:
      return (
        <iframe
          src="https://open.spotify.com/embed-podcast/episode/1aXAsorwMAdSoKA3DtB1pn"
          width="100%"
          height="232"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      );

    default:
      return '';
  }
}
