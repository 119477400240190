import React from 'react';
import PropTypes from 'prop-types';
import Eyebrow from '../../eyebrow/eyebrow';
import FCLink from '../../fc_link/fc_link';

export default function Title(props) {
  const { post, tags, live, config, isFirstPost, insert, supertags } = props;
  const { title, id } = post;
  const key = `title-${id}`;
  const renderPostTitle = () => {
    const link = (
      <FCLink
        key={key}
        config={config}
        externalLink={!isFirstPost}
        to={post.link}
        title={title}
      >
        {title}
      </FCLink>
    );

    // if (isFirstPost) {
    //   return (
    //     <h1 key={key} className="post__title">
    //       {link}
    //     </h1>
    //   );
    // }

    return (
      <h1 key={key} className="post__title">
        {link}
      </h1>
    );
  };

  return [
    <Eyebrow
      key={`eyebrow-${id}`}
      post={post}
      tags={tags}
      live={live}
      insert={insert}
      supertags={supertags}
    />,
    renderPostTitle()
  ];
}

Title.propTypes = {
  config: PropTypes.shape({}).isRequired,
  isFirstPost: PropTypes.bool.isRequired,
  post: PropTypes.shape({}).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  live: PropTypes.bool.isRequired,
  insert: PropTypes.bool
};
