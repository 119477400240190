import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarketoForm from '../../MarketoForm/MarketoForm';

const CompassCoDesign = ({ isCompass, lazy }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div
      className={`newsletter__widget newsletter__widget__PluggedinFooter compassCoDesign widget__state--${
        submitted ? 'submitted' : 'init'
      }`}
    >
      <header>
        <img
          alt="PluggedIn Newsletter logo"
          className="header__logo"
          src={
            isCompass
              ? 'https://assets.fastcompany.com/image/upload/v1687822217/Type_Compass_2x.webp'
              : 'https://assets.fastcompany.com/image/upload/v1687881219/co-design-logo-2020_2x.webp'
          }
          width={240}
          height={60}
        />
        {!submitted && (
          <div>
            <span className="highlight">
              {isCompass
                ? "Today's trending stories, delivered to you daily."
                : 'The latest innovations in design brought to you every weekday.'}
            </span>
          </div>
        )}
      </header>
      {submitted ? (
        <p className="submission success__message">
          <span className="text-primary">
            Thank you! Look out for your first email!
          </span>
        </p>
      ) : (
        <MarketoForm
          assetId={isCompass ? 1449 : 1450}
          name="inline-newsletter-form"
          onSubmitHandler={setSubmitted}
          lazy={lazy}
        />
      )}
      <footer>
        <a
          rel="noopener noreferrer"
          href="https://www.mansueto.com/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

CompassCoDesign.displayName = 'CompassCoDesign';

CompassCoDesign.propTypes = {
  isCompass: PropTypes.bool,
  lazy: PropTypes.bool
};

CompassCoDesign.defaultProps = {
  isCompass: false,
  lazy: false
};

export default CompassCoDesign;
