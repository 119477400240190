import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import axios from 'axios';
import Slug from '../slug/slug';
import Update from './update';
import scrollToEl from '../../../utils/scroll_to_el_util';

export default class Live extends Component {
  constructor(props) {
    super(props);

    const REFRESH_IN_SECONDS = 30;
    this.fetchDelay = REFRESH_IN_SECONDS * 1000;
    this.api = `${props.config.protocol}${props.config.apiEndpoint}`;
    this.state = {
      posts: props.live.posts
    };
    this.scrolledToUpdate = false;
    this.lastSeenParam = '';

    this.updatesPerPage = 100;
    this.fetchLiveUpdates = this.fetchLiveUpdates.bind(this);
    this.fetchPaginatedUpdates = this.fetchPaginatedUpdates.bind(this);
  }

  componentDidMount() {
    const { live } = this.props;
    if (live.enabled) {
      this.fetchLiveUpdates(true);
    } else {
      this.fetchPaginatedUpdates(1, get(live, 'posts.length'));
    }
    this.scrollToUpdateElement();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.posts.length !== nextState.posts.length;
  }

  componentDidUpdate() {
    if (!this.scrolledToUpdate) {
      this.scrollToUpdateElement();
    }

    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }

    if (window.twttr) {
      if (window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refresher);
  }

  setLastSeen() {
    const d = new Date();
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const date = `0${d.getDate()}`.slice(-2);
    const hours = `0${d.getHours()}`.slice(-2);
    const minutes = `0${d.getMinutes()}`.slice(-2);
    const seconds = d.getSeconds() <= 30 ? '00' : 30;
    const formattedDate = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`;
    this.lastSeenParam = `?lastSeen=${formattedDate}`;
  }

  fetchLiveUpdates(initialFetch = false) {
    axios
      .get(`${this.api}/api/v1/live-posts/${this.props.live.tag}`)
      .then(res => {
        this.setLastSeen();

        const livePosts = get(res, 'data.livePosts', []);
        const page = get(res, 'data.page');
        const total = get(res, 'data.total');

        if (get(livePosts, 'length')) {
          livePosts.push(...this.state.posts);

          this.setState({
            posts: uniqBy(livePosts, 'id')
          });
        }

        if (initialFetch) {
          this.fetchPaginatedUpdates(page, total);
        }

        this.refresher = setTimeout(() => {
          this.fetchLiveUpdates();
        }, this.fetchDelay);
      });
  }

  fetchPaginatedUpdates(page, total) {
    if (this.updatesPerPage * page <= total) {
      axios
        .get(
          `${this.api}/api/v1/live-posts/${this.props.live.tag}?page=${page +
            1}`
        )
        .then(res => {
          const nextPagePosts = get(res, 'data.livePosts', []);
          const nextPage = get(res, 'data.page');
          const totalPosts = get(res, 'data.total');
          const livePosts = [...this.state.posts, ...nextPagePosts];

          this.setState({
            posts: uniqBy(livePosts, 'id')
          });

          if (nextPage && this.updatesPerPage * nextPage < totalPosts) {
            this.fetchPaginatedUpdates(nextPage, totalPosts);
          }
        });
    }
  }

  scrollToUpdateElement() {
    const { hash: update } = window.location;

    if (update && document.querySelector(update)) {
      scrollToEl(update, 100);
      this.scrolledToUpdate = true;
    }
  }

  render() {
    const { posts } = this.state;
    const { parentPost, config } = this.props;

    if (!get(posts, 'length')) {
      return null;
    }

    return (
      <div className="live" id="updates">
        <Slug slugName="Updates" className="live__header" />
        {posts.map(post => (
          <Update
            key={post.id}
            update={post}
            parentPost={parentPost}
            site={config.site}
          />
        ))}
      </div>
    );
  }
}

Live.propTypes = {
  config: PropTypes.shape({
    protocol: PropTypes.string,
    apiEndpoint: PropTypes.string
  }).isRequired,
  parentPost: PropTypes.shape({}).isRequired,
  live: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({})),
    tag: PropTypes.string,
    enabled: PropTypes.bool
  }).isRequired
};
