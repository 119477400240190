import get from 'lodash/get';
import { capitalize } from './string_util';

export function containsTag(arr, tag) {
  return arr.some(item => {
    const slug = get(item, 'slug', '');
    return slug === tag;
  });
}

export function determineIfMagazine(categories) {
  return categories.some(category => category.slug === 'magazine');
}

function hasProperty(obj, str) {
  return obj.hasOwnProperty(str);
}

export function determineMagazineIssueNumber(tags) {
  if (!Array.isArray(tags)) {
    return null;
  }

  const regexp = /^issue[-_]\d{0,3}$/;

  const issue = get(
    tags.filter(function filterForIssueNum(tag) {
      const slug = get(tag, 'slug');
      return slug ? slug.match(regexp) : false;
    }),
    '[0].slug'
  );

  return issue || '';
}

export function determineMagazineIssueMonth(tags) {
  if (!Array.isArray(tags)) {
    return null;
  }

  const months = {
    january: '',
    february: '',
    march: '',
    april: '',
    may: '',
    june: '',
    july: '',
    august: '',
    september: '',
    october: '',
    november: '',
    december: ''
  };

  const regexp = /^((\w{3,9})[-_](\d{4})([-_](\w{3,9})[-_](\d{4}))*|((\w{3,9})[-_](\w{3,9})[-_](\d{4})))$/;

  let issue = '';

  for (let i = 0; i < get(tags, 'length'); i += 1) {
    const currTag = tags[i];
    const currTagSlug = get(currTag, 'slug') || '';
    if (regexp.test(currTagSlug)) {
      const matchArr = currTagSlug.match(regexp);
      const testMonth = matchArr[2];
      const year = matchArr[3];
      const isDoubleIssueDiffYear =
        matchArr[5] && matchArr[6] && hasProperty(months, matchArr[5]);

      const dblIssYr = matchArr[10];
      const dblM1 = matchArr[8];
      const dblM2 = matchArr[9];
      const isDoubleIssueSameYear = dblM1 && dblM2 && dblIssYr;

      if (months.hasOwnProperty(testMonth)) {
        issue += `${capitalize(testMonth)} ${year}`;
      }

      if (isDoubleIssueDiffYear) {
        issue += `/${capitalize(matchArr[5])} ${matchArr[6]}`;
      }

      if (
        isDoubleIssueSameYear &&
        hasProperty(months, dblM1) &&
        hasProperty(months, dblM2)
      ) {
        issue += `${capitalize(dblM1)}/${capitalize(dblM2)} ${dblIssYr}`;
      }
    }
  }

  return issue && typeof issue === 'string' ? issue : '';
}
