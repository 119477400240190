import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import striptags from 'striptags';

function truncateDesc(props) {
  const str = props.description || '';
  const cleanedDescription = striptags(str, [
    'a',
    'b',
    'em',
    'i',
    'u',
    'strike',
    'strong'
  ]);

  const descriptionPieces = cleanedDescription.split('. ');

  if (descriptionPieces.length >= 2 && !props.hasFCEB) {
    return descriptionPieces.slice(0, 2).join('. ');
  }

  return descriptionPieces.join('. ');
}

export default function Author(props) {
  if (!props.description) {
    return null;
  }

  if (props.specialRedesignArticle === true) {
    return (
      <div className={`author author--${props.modifier}`}>
        <div className="author-container">
          <p className="author-name author-name">{props.name}</p>
        </div>
        <p
          className="author__description"
          dangerouslySetInnerHTML={{ __html: truncateDesc(props) }}
        />
        {props.hasFCEB ? (
          <a href={props.url ? props.url : `/user/${props.slug}`}> More</a>
        ) : (
          <React.Fragment>
            <Link
              className="author__bio-page mobile"
              to={`/user/${props.slug}`}
            >
              More articles
            </Link>
            <Link className="author__bio-page dt" to={`/user/${props.slug}`}>
              More from this author
            </Link>
            .
          </React.Fragment>
        )}
      </div>
    );
  }

  return (
    <div className={`author author--${props.modifier}`}>
      <h4 className="author__title">About the author</h4>
      {/* eslint-disable */}
      <p className="author__description" dangerouslySetInnerHTML={{ __html: truncateDesc(props) }} />
      <p className="author__description">
        {props.hasFCEB ? 
          <a href={props.url ? props.url : `/user/${props.slug}`}> More</a> :
          <Link className="author__bio-page" to={`/user/${props.slug}`}> More</Link>
        }
      </p>
      {/* eslint-enable */}
    </div>
  );
}

Author.defaultProps = {
  specialRedesignArticle: false,
  url: undefined,
  slug: undefined
};

Author.propTypes = {
  modifier: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  specialRedesignArticle: PropTypes.bool,
  hasFCEB: PropTypes.bool.isRequired,
  url: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string.isRequired
};
