import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Card from '../../card/card';
import cloneDeep from 'lodash/cloneDeep';
import FCSlider from '../../fc_slider/fc_slider';
import generateTagData from '../../../../utils/advertorial_utils';
import EventModel from '../../../../utils/tracking/models';
import {
  isAdvertorial,
  isPremium
} from '../../../../views/components/advertorial/advertorial';
import log from '../../../../services/logger_service';
import Cookies from 'js-cookie';
import MobileVideoPlayer from '../../mobile_video_module/mobile_video_module_reactjw';
import { trackGAEvent } from '../../../../client/utils/third_party_trackers/ga';
import AccordionGallery from '../../accordion_gallery/accordion_gallery';
import { UserConsumer } from '../../../components/context/userContext';
import AdContainer from '../../ad/AdContainer';
import BottomSticky from '../utils/BottomSticky';
import BlueConicPremiumPaywall from '../../BlueConicPremiumPaywall/BlueConicPremiumPaywall';
import generateVideoCustomParams from '../../../../utils/seo_utils/video/video_post_custom_params_util';
import clsx from 'clsx';

/* eslint no-nested-ternary: 0 */
/* eslint-disable array-callback-return, consistent-return */

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      midArticleInjection: null,
      alwaysOn: null,
      hideMobileVideoPlayer: props.hideMobileVideoPlayer,
      showGlobalPlayer: null,
      // mobileAdhesiveUnitShouldShow: Math.random() > 0.25 // Show only 75% of the time - excluding redesign article
      mobileAdhesiveUnitShouldShow: true // Show 100% of time for now
    };

    this.renderNativeAlwaysOn = this.renderNativeAlwaysOn.bind(this);
    this.hideMobileVideoPlayer = this.hideMobileVideoPlayer.bind(this);
    this.checkPaidCampaign = this.checkPaidCampaign.bind(this);
    this.hasMobilePlayerCategoryExemption = this.hasMobilePlayerCategoryExemption.bind(
      this
    );

    this.isMobile = false;
    this.showPlayer = false; // Remove once debugged.
    this.showDesktopBillboard = true; // Hard/static display boolean for ad purposes
    this.showLiveBanner = false;
    this.didMobileVideoPlayerShow = false;
    this.articleInjectionShown = false;

    this.parsLoaded = 0;
    this.utmCode = '';
    this.campaignsArr = [
      '?utm_source=google&utm_medium=ppc&utm_campaign=keywee',
      '?utm_source=facebook&utm_medium=ppc&utm_campaign=keywee'
    ];
  }

  componentDidMount() {
    this.checkWindowSize();
    this.utmCode = window.location.search || '';
    // window.addEventListener('resize', this.checkWindowSize.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (isAdvertorial(get(nextProps, 'post.tags'))) {
      return;
    }

    if (
      !this.state.midArticleInjection &&
      get(nextProps, 'postNativeAd.length')
    ) {
      const modifiedInjectionArticle = get(nextProps, 'postNativeAd[0]');
      const advertiserData = generateTagData(
        get(modifiedInjectionArticle, 'tags')
      );

      modifiedInjectionArticle.customEyebrowText = get(
        advertiserData,
        'advertiser'
      );

      this.setState({
        midArticleInjection: [modifiedInjectionArticle]
      });
    }
  }

  /**
   * Handle event for when the mobile video player is shown.
   * @param {boolean} value - The new value of mobileVideoPlayerShown.
   */
  onMobileVideoPlayerShown(value) {
    const oldValue = this.mobileVideoPlayerShown;
    this.mobileVideoPlayerShown = value;

    if (!oldValue && value) {
      const mobileVideoPlayerShownEvent = new EventModel();
      mobileVideoPlayerShownEvent.pushKeyValueToSpeedCurve(
        'mobileVideoPlayerShown',
        1
      );
    }
  }

  /**
   * Handle event for when the article injection is shown.
   * @param {boolean} value - The new value for articleInjectionShown.
   */
  onArticleInjectionShown(value) {
    const oldValue = this.articleInjectionShown;
    this.articleInjectionShown = value;

    if (!oldValue && value) {
      const articleInjectionShownEvent = new EventModel();
      articleInjectionShownEvent.pushKeyValueToSpeedCurve(
        'articleInjectionShown',
        1
      );
    }
  }

  setPostChunk(
    chunkOriginal,
    index,
    totalChunkLength,
    id,
    showDrawer,
    postAuthor = ''
  ) {
    const chunk = [...chunkOriginal];
    const self = this;
    const { post, advertorial } = this.props;
    const chunks = get(post, 'content', []);
    const paywallViewLimit = 2; // SHOULD BE DYNAMIC FROM JOURNEY ULTIMATELY!!!
    const chunkHasInlineImage = chunk.join('').includes('wp-block-image');
    const isPremiumArticle = isPremium(get(post, 'tags', []));
    const preventWithOtherInjections = false;
    const midPositionCalculation = Math.ceil(chunk.length / 2);

    // Handles if the chunk contains a header, we dont want to insert it right after the header
    const midPosition =
      chunk[midPositionCalculation - 1] &&
      (chunk[midPositionCalculation - 1].includes('<h2>') ||
        chunk[midPositionCalculation - 1].includes('<h3>'))
        ? Math.floor(chunk.length / 2 + 1)
        : midPositionCalculation;

    let chunkingStopped = get(this, 'chunkingStopped', false);
    let currentParCount = get(this, 'parsLoaded', 0);

    const alwaysOnInjection =
      this.state.alwaysOn && index === Math.floor(totalChunkLength / 2)
        ? this.state.alwaysOn
        : '';

    const podcastEmbedIndex = get(post, 'categories', []).findIndex(
      category => typeof category.podcastEmbed !== 'undefined'
    );

    if (index === Math.floor(totalChunkLength / 3)) {
      const podcastChunk = get(
        post,
        `categories[${podcastEmbedIndex}].podcastEmbed`,
        ''
      );

      // chunk.splice(podcastChunk);
      chunk.push(podcastChunk);
    }

    // Resetting paragraph counter and chunking flag between renders
    if (index === 0) {
      currentParCount = 0;
      this.chunkingStopped = false;
      chunkingStopped = false;
    }
    const paywallLimitReached =
      showDrawer && chunk.length + currentParCount > paywallViewLimit;

    if (chunkingStopped && !advertorial) {
      return null;
    }

    // Strip paragtraphs that go over paywall limit
    if (paywallLimitReached && !advertorial) {
      const toRemove = chunk.length + currentParCount - paywallViewLimit - 1;
      chunk.splice(chunk.length - toRemove, toRemove);
      this.chunkingStopped = true;
    } else {
      this.parsLoaded = currentParCount + chunk.length;
    }

    // Use webp for inline images
    if (chunkHasInlineImage) {
      const imageChunks = chunk.map(item => {
        if (item.includes('wp-block-image')) {
          if (item.includes('<video')) {
            // animated image
            const animatedImageCode = item.replace('f_jpg', 'f_webp');
            return (
              <div dangerouslySetInnerHTML={{ __html: animatedImageCode }} />
            );
          } // normal (static) image
          const imageSource =
            item
              .match(/data-src="([a-zA-Z:/._0-9,-]+)"/)?.[1]
              .replace(',f_auto', '')
              .replace('w_596', 'w_auto') || null;

          // Use classname logic from original api response
          const priorClassNames =
            item.match(/figure class="([a-zA-Z0-9\s-]+)"/)?.[1] || null;

          const imageCaption =
            item.match(/<figcaption>(.*)<\/figcaption>/)?.[1] || null;

          if (!imageSource) return;

          return (
            <div className={`inlineImage__container ${priorClassNames || ''}`}>
              <picture>
                <source
                  media="(min-width: 415px)"
                  srcset={imageSource.replace('w_auto', 'w_1200')}
                />
                <source
                  media="(max-width: 414px)"
                  srcset={imageSource.replace('w_auto', 'w_828')}
                />
                <img
                  src={imageSource.replace('w_auto', 'w_1200')}
                  loading="lazy"
                  alt=""
                  width="640"
                  height="320"
                  data-explicit-width="640"
                  data-explicit-height="320"
                />
              </picture>

              <div
                className="image-caption"
                dangerouslySetInnerHTML={{ __html: imageCaption }}
              />
            </div>
          );
        }

        return <div dangerouslySetInnerHTML={{ __html: item }} />;
      });

      return imageChunks;
    }

    const showMidArticleInjection =
      this.state.midArticleInjection &&
      index === Math.floor(totalChunkLength / 2);

    if (showMidArticleInjection) {
      this.onArticleInjectionShown(true);
    }

    const midArticleInjection = showMidArticleInjection
      ? this.state.midArticleInjection.map(article => (
          <div
            className="native-injection"
            key={`native-injection-${article.id}`}
          >
            <Card
              config={this.props.config}
              type="injection"
              metadata={article}
              key={article.id}
              cardEvent={() => {
                trackGAEvent({
                  eventCategory: 'Engagement',
                  eventAction: 'nativeMidArticleInjection',
                  eventLabel: get(article, 'title')
                });
              }}
            />
          </div>
        ))
      : '';

    if (
      // this.showPlayer === true && // Un-comment this line disable entirely!
      this.props.pageNum === 0 &&
      // change this number to determine where the mobile sticky player shows up in the article:
      index === 1 &&
      this.isMobile &&
      // !Cookies.get('fc_rev_content') &&
      !Cookies.get('hideMobileVideoPlayer') &&
      !this.state.hideMobileVideoPlayer
    ) {
      const markupFirstHalf = [...chunk.slice(0, 1)].join('');
      const markupSecondHalf = [...chunk.slice(1, chunk.length)].join('');

      if (
        (chunks.join('').indexOf('video-wrapper') !== -1 &&
          preventWithOtherInjections) ||
        this.checkPaidCampaign(this.utmCode, this.campaignsArr)
      ) {
        return (
          // RETURN WITHOUT MOBILE VIDEO INSERT IF THERE IS A VIDEO IN THE ARTICLE
          <Fragment key={`chunk-${index}`}>
            <div dangerouslySetInnerHTML={{ __html: markupFirstHalf }} />
            {this.state.showGlobalPlayer !== null && (
              <div>{this.state.showGlobalPlayer}</div>
            )}
            <div dangerouslySetInnerHTML={{ __html: markupSecondHalf }} />
          </Fragment>
        );
      }

      if (
        this.state.showGlobalPlayer == null &&
        this.state.mobileAdhesiveUnitShouldShow &&
        !this.props.specialRedesignArticle &&
        !isPremiumArticle
      ) {
        this.onMobileVideoPlayerShown(true);
      }
      return (
        // return WITH mobile video insert because there is no video in the article...
        // we just dont want a video ad to appear if there is a video in the article already
        <Fragment key={`chunk-${index}`}>
          <div dangerouslySetInnerHTML={{ __html: markupFirstHalf }} />
          {this.state.showGlobalPlayer == null &&
            this.state.mobileAdhesiveUnitShouldShow &&
            !this.props.specialRedesignArticle &&
            !isPremiumArticle &&
            !this.hasMobilePlayerAuthorExemption(postAuthor) &&
            !this.hasMobilePlayerCategoryExemption(['News', 'Co.Design']) && (
              <BottomSticky getAdhesiveHeight={this.props.getAdhesiveHeight}>
                <MobileVideoPlayer
                  mobileInline={true}
                  type="rightrail"
                  playerid="delVxmba"
                  // hard coded playlist for alison/jonelle
                  playlistId="5CQr0On3"
                  pos="newmultiplayer"
                  hideMobileVideoPlayer={self.hideMobileVideoPlayer}
                  customparams={generateVideoCustomParams(post)}
                />
              </BottomSticky>
            )}
          <div dangerouslySetInnerHTML={{ __html: markupSecondHalf }} />
        </Fragment>
      );
    }

    const markupFirstHalf = [...chunk.slice(0, midPosition)].join('');
    const markupSecondHalf = [...chunk.slice(midPosition, chunk.length)].join(
      ''
    );

    if (markupFirstHalf === 'INLINE_CAROUSEL') {
      return (
        <FCSlider
          key={`inline-slideshow-${id}-${index}`}
          slideShowData={this.inlineCarousels.shift()}
          location="post__inline"
        />
      );
    }

    // just return whole chunk if there's no article injection
    if (midArticleInjection === '' && this.state.alwaysOn == null) {
      return (
        <Fragment key={`chunk-${index}`}>
          <div dangerouslySetInnerHTML={{ __html: chunk.join('') }} />
        </Fragment>
      );
    }

    if (midArticleInjection && alwaysOnInjection) {
      const choice = Math.floor(Math.random() * 2);
      const nativeArray = [midArticleInjection, alwaysOnInjection];

      return (
        <Fragment key={`chunk-${index}`}>
          <div dangerouslySetInnerHTML={{ __html: markupFirstHalf }} />
          {nativeArray[choice]}
          <div dangerouslySetInnerHTML={{ __html: markupSecondHalf }} />
        </Fragment>
      );
    }

    if (midArticleInjection) {
      return (
        <Fragment key={`chunk-${index}`}>
          <div dangerouslySetInnerHTML={{ __html: markupFirstHalf }} />
          {midArticleInjection}
          <div dangerouslySetInnerHTML={{ __html: markupSecondHalf }} />
        </Fragment>
      );
    }

    if (alwaysOnInjection) {
      return (
        <Fragment key={`chunk-${index}`}>
          <div dangerouslySetInnerHTML={{ __html: markupFirstHalf }} />
          {alwaysOnInjection}
          <div dangerouslySetInnerHTML={{ __html: markupSecondHalf }} />
        </Fragment>
      );
    }

    return (
      <Fragment key={`chunk-${index}`}>
        <div dangerouslySetInnerHTML={{ __html: chunk.join('') }} />
      </Fragment>
    );
  }

  /**
   * Determines whether or not to display the mobile video by author slug.
   * @param {string} authorSlug - The `slug` of the author to check.
   * @returns {boolean} - Whether or not to display the mobile video module.
   */
  hasMobilePlayerAuthorExemption(authorSlug) {
    const exemptAuthors = [
      'lancelambert' // Lance Lambert
    ];
    return exemptAuthors.includes(authorSlug);
  }

  // Checks the window size and updates `isMobile` property
  checkWindowSize() {
    const mobileBreakpoint = 640; // in px
    this.isMobile = window.innerWidth < mobileBreakpoint;
    this.forceUpdate();
  }

  hideMobileVideoPlayer() {
    /* eslint-disable-next-line func-names */
    this.setState({ hideMobileVideoPlayer: true }, function() {
      Cookies.set('hideMobileVideoPlayer', true, { expires: 1 });
      this.forceUpdate();
    });
  }

  notAroundCarousel(chunks, currentChunk, index) {
    const next = chunks[index + 1] || [];
    const nextZeroIndex = next[0];
    const carousel = 'INLINE_CAROUSEL';

    if (nextZeroIndex === carousel || currentChunk === carousel) {
      return false;
    }

    return true;
  }

  checkPaidCampaign(utmCode, campaignsArr) {
    // return campaignsArr.includes(utmCode);
    return campaignsArr.indexOf(utmCode) > -1;
  }

  hasMobilePlayerCategoryExemption(exemptCategories) {
    const { post } = this.props;
    const postCategories = post?.categories || [];
    const match = postCategories.some(category =>
      exemptCategories.includes(category?.name)
    );
    return match;
  }

  renderNativeAlwaysOn(response, index) {
    const alwaysOn = (
      <a href={response.clickthrough_url} target="_blank">
        <div
          style={{
            backgroundImage: `url(${response.bg_image})`,
            height: '180px',
            width: '100%',
            backgroundSize: 'contain',
            margin: '20px auto',
            textAlign: 'center',
            backgroundRepeat: 'no-repeat'
          }}
          className={`alwaysOn_${index}`}
        />
      </a>
    );
    this.setState({ alwaysOn });
  }

  render() {
    const { config, post, advertorial, isShortPost } = this.props;

    const thePostId = get(post, 'id', 'unknown-post-id');

    const masonryCollection = get(post, 'customFields.masonry_collection', []);
    const isPremiumArticle = isPremium(get(post, 'tags', []));

    const chunks = get(post, 'content', []);
    if (!chunks.length) {
      log.error(
        'No article chunk found, this article has no body content: ',
        get(post, 'link')
      );
    }
    this.inlineCarousels = cloneDeep(get(post, 'inlineCarousels')) || [];
    const nativePixelContentArticlePageFeatureFlag = get(
      config,
      'featureFlags.nativePixelContentArticlePage'
    );

    const { isFirstPost = true } = this.props;

    let nativeAds = null;
    if (isFirstPost) {
      nativeAds = (
        <React.Fragment>
          {thePostId !== 91011233 && <AdContainer type="native_link" />}
          <AdContainer type="native_second_scroll" />
        </React.Fragment>
      );
    }

    const nativeAdPixel = nativePixelContentArticlePageFeatureFlag ? (
      <div>{nativeAds}</div>
    ) : (
      ''
    );

    if (post.isVideoPermalink) {
      return (
        <article className="post__article">
          <p>{post.excerpt}</p>
        </article>
      );
    }

    if (advertorial) {
      for (let i = 0; i < chunks.length; i += 1) {
        for (let j = 0; j < chunks[i].length; j += 1) {
          const regex = /<a[^>]*/gm;
          let matches;

          // eslint-disable-next-line
          while ((matches = regex.exec(chunks[i][j])) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (matches.index === regex.lastIndex) {
              regex.lastIndex += 1;
            }
            // eslint-disable-next-line no-loop-func
            matches.forEach(match => {
              chunks[i][j] = chunks[i][j].replace(
                match,
                `${match} rel="nofollow"`
              );
            });
          }
        }
      }
    }

    return (
      <UserConsumer>
        {({ isLoggedIn, hasPaywallAccess }) => {
          // show bc paywall if premium article even if its on the second scroll
          const isPreviewArticle = this.props?.config?.isPreview;
          const showDrawer =
            (!isLoggedIn || !hasPaywallAccess(thePostId)) &&
            isPremiumArticle &&
            !isPreviewArticle;
          const exceptions = new RegExp(
            // eslint-disable-next-line no-useless-escape
            /<h2|<h3|<ul|<ol|<strong|<blockquote|<iframe|<img|class=\"pullquote/,
            'gi'
          );
          // Dont show mid article inject if any of the tags above ^ are beside it:
          if (
            Array.isArray(get(chunks, '[2]')) &&
            Array.isArray(get(chunks, '[1]')) &&
            (exceptions.test(chunks[2][0]) ||
              exceptions.test(chunks[1][chunks[1].length - 1]))
          ) {
            this.showDesktopBillboard = false;
          }
          return (
            <Fragment>
              <article
                className={clsx(
                  'post__article',
                  advertorial && 'post__article--advertorial',
                  showDrawer && 'post__article--lock'
                )}
              >
                {this.showLiveBanner && (
                  <div className="fcf-live-banner">
                    <a href="https://events.fastcompany.com/innovationfestival21/keynote-pass">
                      Watch the Fast Company Innovation Festival Live now.
                    </a>
                  </div>
                )}
                {chunks.map((chunk, index) => [
                  this.setPostChunk(
                    chunk,
                    index,
                    chunks.length,
                    post.id,
                    showDrawer,
                    post?.author?.slug
                  ),
                  !isShortPost && index < chunks.length - 1 ? (
                    this.notAroundCarousel(chunks, chunk[0], index) ? (
                      this.isMobile && !isPremiumArticle ? ( // if the article is not a recommender/premium article, serve ads on mobile...
                        <div
                          key={`recommenderMobileA-${index}-for-${thePostId}`}
                        >
                          {index === 0 && (
                            <div id="fc-anyclip">
                              <AdContainer
                                key={`chunk-ad-${index}`}
                                type={isPremiumArticle ? 'rail' : 'in_content'}
                                page={isFirstPost ? 1 : 2}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          key={`recommenderMobileB-${index}-for-${thePostId}`}
                        >
                          {index === Math.floor(chunks.length / 2) ? ( // ... otherwise serve only 1 ad on recommender/premium articles
                            <AdContainer
                              key={`chunk-ad-${index}`}
                              type={isPremiumArticle ? 'rail' : 'in_content'}
                              page={isFirstPost ? 1 : 2}
                            />
                          ) : null}
                        </div>
                      )
                    ) : null
                  ) : null,
                  nativePixelContentArticlePageFeatureFlag &&
                    get(post, 'totalParagraphs') >= 7 &&
                    !advertorial &&
                    thePostId !== 91011233 &&
                    index === 0 && (
                      <div
                        key={`native-mid-article-inject-${index}-for-${thePostId}`}
                        className={`adInserts_${index}`}
                      >
                        {isFirstPost ? (
                          <AdContainer type="native_mid_article" />
                        ) : null}
                      </div>
                    )
                ])}
                {masonryCollection &&
                  masonryCollection.map((data, index) => (
                    <AccordionGallery
                      key={`AccordionGallery-${index}`}
                      {...data}
                    />
                  ))}
                {nativeAdPixel}
                <div
                  id="collapsible-placeholder"
                  style={{
                    width: '100%',
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    zIndex: '9999',
                    backgroundColor: '#FFFFFF'
                  }}
                />
                {this.isMobile && (
                  <div
                    id="collapsible-mobile-video-unit"
                    style={{
                      width: '100%',
                      position: 'fixed',
                      bottom: '0',
                      left: '0',
                      zIndex: '9999',
                      backgroundColor: 'offwhite'
                    }}
                  />
                )}
              </article>
              {showDrawer && <BlueConicPremiumPaywall />}
            </Fragment>
          );
        }}
      </UserConsumer>
    );
  }
}

Article.defaultProps = {
  isFirstPost: true,
  specialRedesignArticle: false,
  getAdhesiveHeight: () => 0,
  insert: false,
  hideMobileVideoPlayer: false,
  postCategories: []
};

Article.propTypes = {
  advertorial: PropTypes.bool.isRequired,
  post: PropTypes.shape({}).isRequired,
  pageNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  config: PropTypes.shape({
    isPreview: PropTypes.bool
  }).isRequired,
  isShortPost: PropTypes.bool.isRequired,
  isFirstPost: PropTypes.bool,
  specialRedesignArticle: PropTypes.bool,
  getAdhesiveHeight: PropTypes.func,
  insert: PropTypes.bool,
  hideMobileVideoPlayer: PropTypes.bool,
  postCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  )
};

function mapStateToProps(state = {}) {
  const props = {
    postNativeAd: [],
    hideMobileVideoPlayer: true
  };

  const nativeAdInjectData = get(
    state,
    'postNativeAd.data.midArticleInject.posts'
  );

  if (nativeAdInjectData) {
    props.postNativeAd = nativeAdInjectData;
  }

  const tags = get(state, 'post.data.post.tags', []);

  const excludeVideoTags = [
    'cybersecurity',
    'mindfulness-at-work',
    'financing-the-future'
  ];

  props.hideMobileVideoPlayer = !!tags.find(tag =>
    excludeVideoTags.includes(tag.slug)
  );

  const excludeVideoPostIds = [
    90938716, // The quest to create the next wordle
    90951624 // Inside Selena Gomez's beauty juggernaut
  ];

  const postId = get(state, 'post.data.post.id', '');
  if (excludeVideoPostIds.includes(postId)) {
    props.hideMobileVideoPlayer = true;
  }

  return props;
}

export default connect(mapStateToProps)(Article);
