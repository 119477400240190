import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  determineIfMagazine,
  determineMagazineIssueMonth,
  determineMagazineIssueNumber,
  containsTag
} from '../../../../utils/tag_category_util';
import MagazineAttribution from '../../magazine_attribution/magazine_attribution';
import Author from './author';
import PersonalizedRecommendations from '../../personalized_recommendations/personalized_recommendations';
import featureFlags from '../../../../../feature_flags';
import NewsletterWidget from '../../NewsletterWidget/NewsletterWidget';
import loadable from '@loadable/component';

const Topics = loadable(() => import('./topics'));

const Footer = props => {
  const {
    post,
    isLongPost,
    hasNewsletter,
    bylineAttribution,
    config,
    promoText,
    hasFCEB,
    canRenderInfiniteScroll,
    isFirstPost
  } = props;
  const { tags, categories, author, link, customFields } = post;
  const isMagazine = determineIfMagazine(categories);
  const hasAuthor = isLongPost && !bylineAttribution;
  const isAdvertorial = containsTag(tags, 'advertorial');
  const { guestAuthors } = customFields;
  // Hide Topics component for SEO requirements on article page if tags include any of the following
  const hideTagsList = [
    'fast-company-executive-board',
    '_advertorial',
    'digest',
    'advertorial',
    '_custom',
    'homepage',
    'dailydigest',
    'featured',
    'byline',
    'paywall-exclude'
  ];
  const regexPattern = /[*_]/;
  // eslint-disable-next-line no-unused-expressions
  const arr = tags?.map(tag => {
    if (tag && tag.slug && !regexPattern.test(tag?.slug)) {
      if (!hideTagsList.some(str => tag?.slug.includes(str))) {
        return {
          ...tag,
          name: tag.name.replace(/[_-]/g, ' ')
        };
      }
    }
    return null;
  });
  const results = arr.filter(Boolean).slice(0, 8);

  const hasPersonalizedRecommendations =
    featureFlags.postPersonalizedRecommendationsEnabled &&
    !containsTag(tags, 'advertorial');

  const abGroupCookie = Cookies.get('abgroup');
  let defaultABGroup = 0;
  let showRecommendations = false;
  if (abGroupCookie) {
    defaultABGroup = parseInt(abGroupCookie, 10);
  }

  if (defaultABGroup === 1 || defaultABGroup === 2) {
    showRecommendations = true;
  }

  if (!isMagazine && !hasAuthor && !hasNewsletter) {
    return null;
  }

  const checkAITags = () => {
    const aiTags = ['generative-ai', 'ai-decoded', 'artificial-intelligence'];
    return tags.some(tag => aiTags.includes(tag.slug));
  };

  const checkModernCEOTags = () => {
    const modernTags = ['modern-ceo'];
    return tags.some(tag => modernTags.includes(tag.slug));
  };

  const getNewsletterType = () => {
    let type = 'modernCeo';

    if (containsTag(categories, 'technology')) {
      type = 'pluggedIn';
      if (checkAITags()) {
        type = 'aiDecoded';
      }
    } else if (
      containsTag(categories, 'co-design') ||
      containsTag(categories, 'news')
    ) {
      type = 'compassCoDesign';
      if (checkAITags()) {
        type = 'aiDecoded';
      }
    } else if (containsTag(categories, 'leadership')) {
      type = 'workSmarter';
      if (checkAITags()) {
        type = 'aiDecoded';
      }
    }

    if (
      containsTag(categories, 'leadership') ||
      containsTag(categories, 'work-life')
    ) {
      if (checkModernCEOTags()) type = 'modernCeo';
    }

    return {
      [type]: true,
      isCompass: containsTag(categories, 'news') ? true : undefined
    };
  };

  return (
    <footer className="post__footer">
      {isMagazine ? (
        <MagazineAttribution
          issueMonth={determineMagazineIssueMonth(tags)}
          issueNumber={determineMagazineIssueNumber(tags)}
          config={config}
        />
      ) : (
        ''
      )}
      {props.specialRedesignArticle &&
        hasAuthor &&
        !guestAuthors && (
          <Author
            modifier="post"
            {...author}
            hasFCEB={hasFCEB}
            specialRedesignArticle
          />
        )}
      {promoText && (
        <div
          className="globalPostFooter"
          dangerouslySetInnerHTML={{ __html: promoText }}
        />
      )}
      {!isAdvertorial &&
        isFirstPost && (
          <div className="post__footer__newsletter">
            <NewsletterWidget {...getNewsletterType()} lazy={true} />
          </div>
        )}
      {hasAuthor &&
        !guestAuthors &&
        !props.specialRedesignArticle && (
          <Author modifier="post" {...author} hasFCEB={hasFCEB} />
        )}
      {results && results?.length > 0 && <Topics tags={results} />}
      {hasPersonalizedRecommendations &&
      showRecommendations &&
      canRenderInfiniteScroll ? (
        <PersonalizedRecommendations
          post={post}
          config={config}
          link={link}
          isAdvertorial={containsTag(tags, 'advertorial')}
        />
      ) : (
        ''
      )}
    </footer>
  );
};

Footer.defaultProps = {
  promoText: '',
  hasFCEB: false,
  canRenderInfiniteScroll: false,
  isFirstPost: true,
  specialRedesignArticle: false
};

Footer.propTypes = {
  post: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    author: PropTypes.shape({}).isRequired
  }).isRequired,
  config: PropTypes.shape({}).isRequired,
  bylineAttribution: PropTypes.string.isRequired,
  isLongPost: PropTypes.bool.isRequired,
  hasNewsletter: PropTypes.bool.isRequired,
  promoText: PropTypes.string,
  hasFCEB: PropTypes.bool,
  canRenderInfiniteScroll: PropTypes.bool,
  isFirstPost: PropTypes.bool,
  specialRedesignArticle: PropTypes.bool
};

export default Footer;
