import React from 'react';
import get from 'lodash/get';
import AdContainer from '../../ad/AdContainer';

export default function PhotoEssaySection(props) {
  const index = get(props, 'index', '');
  const adFrequency = get(props, 'adFrequency', 1);
  const lastSection = get(props, 'lastSection', false);
  const header = get(props, 'section.section_header', '');
  const description = get(props, 'section.section_description', '');
  const photos = get(props, 'section.photos', []) || [];

  return (
    <div className="photo-essay-section">
      <div className="border" />
      <div className="header">{header}</div>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />

      {photos.map(photo => {
        const format = get(photo, 'format', '');
        if (format === '1 Column') {
          let title;
          let image = get(photo, 'image_1_upload.url', '');
          if (image) {
            title = get(photo, 'image_1_upload.title', '');
          } else {
            image = get(photo, 'image_1_url', '');
            title = null;
          }
          const caption = get(photo, 'image_1_caption', '');

          return (
            <div className="image-row">
              <img src={image} alt={title} />
              <div
                className="caption"
                dangerouslySetInnerHTML={{
                  __html: caption
                }}
              />
            </div>
          );
        }

        if (format === '2 Column') {
          let title;
          let image = get(photo, 'image_1_upload.url', '');
          if (image) {
            title = get(photo, 'image_1_upload.title', '');
          } else {
            image = get(photo, 'image_1_url', '');
            title = null;
          }
          const caption = get(photo, 'image_1_caption', '');

          let title2;
          let image2 = get(photo, 'image_2_upload.url', '');
          if (image2) {
            title2 = get(photo, 'image_2_upload.title', '');
          } else {
            image2 = get(photo, 'image_2_url', '');
            title2 = null;
          }
          const caption2 = get(photo, 'image_2_caption', '');

          return (
            <div className="image-row two-column">
              <div className="two-column-container">
                <img src={image} alt={title} />
                <div
                  className="caption"
                  dangerouslySetInnerHTML={{
                    __html: caption
                  }}
                />
              </div>
              <div className="two-column-container">
                <div>
                  <img src={image2} alt={title2} />
                  <div
                    className="caption"
                    dangerouslySetInnerHTML={{
                      __html: caption2
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}

      {!lastSection &&
        index % adFrequency === 0 && (
          <div>
            <AdContainer type="break" />
          </div>
        )}
    </div>
  );
}
