import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Overlay from './overlay';

class AccordionGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.active,
      layout: 'grid',
      selected: undefined
    };

    this.handleLayoutChange = this.handleLayoutChange.bind(this);
    this.handleActiveToggle = this.handleActiveToggle.bind(this);
    this.handleItemSelected = this.handleItemSelected.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  // width defaulted to largest image width
  setCloudinaryOptimizations(img = '', width = 450) {
    const filters = `w_${width},c_fill,g_auto,f_auto,q_auto,fl_lossy`;
    return img.replace('wp-cms', `${filters}/wp-cms`);
  }

  handleKeyDown(event) {
    switch (event.key) {
      case 'Escape':
        this.setState({ selected: false });
        break;
      default:
        break;
    }
  }

  handleLayoutChange(layout) {
    this.setState({
      layout
    });
  }

  handleActiveToggle() {
    this.setState({
      active: !this.state.active
    });
  }

  handleItemSelected(selected) {
    if (!this.state.active) {
      this.setState({ active: true });
    } else {
      this.setState({ selected });
    }
  }

  render() {
    const { title = '', description = '', entries = [] } = this.props;
    const { layout, active, selected } = this.state;

    return (
      <Fragment>
        <div className="accordion_gallery">
          <header>
            <h3>{title}</h3>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <ul className="layout">
              <li
                className={`view grid ${layout === 'grid' ? 'active' : ''}`}
                onClick={() => this.handleLayoutChange('grid')}
              />
              <li
                className={`view single ${layout === 'single' ? 'active' : ''}`}
                onClick={() => this.handleLayoutChange('single')}
              />
            </ul>
            <button
              className={`toggle ${active ? 'toggle--active' : ''}`}
              onClick={this.handleActiveToggle}
            />
          </header>
          <div
            className={`gallery_wrapper ${
              active ? '' : 'gallery_wrapper--inactive'
            }`}
          >
            <div className={`gallery_container gallery_container--${layout}`}>
              {entries.map((data, index) => (
                <div
                  className="item"
                  onClick={() => this.handleItemSelected(data)}
                  key={`item-${index}`}
                >
                  <img
                    src={this.setCloudinaryOptimizations(data.image, 540)}
                    alt=""
                  />
                  <div className="item__overlay">
                    <p className="item__title">{data.title}</p>
                    <p className="item__director">{data.director}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {selected && (
          <Overlay
            {...selected}
            handleOnClose={() => this.handleItemSelected()}
            setCloudinaryOptimizations={this.setCloudinaryOptimizations}
          />
        )}
      </Fragment>
    );
  }
}

AccordionGallery.defaultProps = {
  active: false
};

AccordionGallery.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf({}).isRequired,
  active: PropTypes.bool
};

export default AccordionGallery;
