import React from 'react';
import componentMap from '../../../utils/componentMap';
import ArticleFooter from './types/ArticleFooter';
import PluggedinFooter from './types/PluggedinFooter';
import WorkSmarterFooter from './types/WorkSmarterFooter';
import ModernCEO from './types/ModernCEO/index';
import CompassCoDesign from './types/CompassCoDesign';
import AIDecoded from './types/AIDecoded';

const NewsletterWidget = props => {
  // Determine view
  const Component = componentMap(props, {
    modernCeo: ModernCEO,
    articleFooter: ArticleFooter,
    pluggedIn: PluggedinFooter,
    workSmarter: WorkSmarterFooter,
    compassCoDesign: CompassCoDesign,
    aiDecoded: AIDecoded,
    default: ArticleFooter
  });

  return <Component type={Component.displayName} {...props} />;
};

NewsletterWidget.displayName = 'NewsletterWidget';

export default NewsletterWidget;
