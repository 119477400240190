import { canUseDOM } from 'exenv';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Video from 'react-jw-player';
import Cookies from 'js-cookie';
import GoogleIMAGenerator from '../video_module/utils/video_module_ads';
import log from '../../../services/logger_service';
import ModuleHeader from './ModuleHeader';

let scriptjs = false;
let ifvisible = false;

if (canUseDOM) {
  // eslint-disable-next-line global-require
  scriptjs = require('scriptjs');
  // eslint-disable-next-line global-require
  ifvisible = require('ifvisible.js');
}

export default class MobileVideoReactJW extends Component {
  constructor(props) {
    super(props);
    this.generatePreRoll = this.generatePreRoll.bind(this);
    this.setMoatTracking = this.setMoatTracking.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onAdComplete = this.onAdComplete.bind(this);
    this.onVideoLoad = this.onVideoLoad.bind(this);
    this.showCollapseButton = this.showCollapseButton.bind(this);
    this.toggleVideoPlayer = this.toggleVideoPlayer.bind(this);
    // this.trackMoatVideoProgress = this.trackMoatVideoProgress.bind(this);

    this.state = {
      videoTitle: this.props.initialTitle ? this.props.initialTitle : '',
      // userIsActive: true,
      firstimp: false,
      // adDataForMoat: null,
      calledMoatEventTrackerMarker: {},
      adIsPlaying: false,
      adIsDone: false,
      windowWidth: 0,
      resizePlayer: false,
      iconUrl:
        'https://assets.fastcompany.com/image/upload/v1684254141/collapse-icon_2x.webp'
    };

    this.videoContainerRef = React.createRef();
    this.MoatApiReference = null;

    // variable init
    this.adPosition = 0;
    this.lastKnownPlaybackPosition = 0;
    this.lastSystemClock = 0;
    this.mediaInfo = {};
    this.customVideoMetadata = {};

    // flags
    this.sessionStarted = false;
    this.playSent = false;
    this.adsPlaying = false;
  }

  componentDidMount() {
    if (scriptjs) {
      scriptjs(['https://z.moatads.com/jwplayerplugin0938452/moatplugin.js']);
    }
  }

  componentWillUnmount() {
    if (canUseDOM) {
      if (window.moatjw) {
        window.moatjw.removeAll();
      }

      if (ifvisible) {
        ifvisible.off('idle');
        ifvisible.off('wakeup');
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  toggleVideoPlayer() {
    const { playerid } = this.props;
    const player = window.jwplayer(playerid);
    const screenWidth = player.getWidth();
    const expandUrl =
      'https://assets.fastcompany.com/image/upload/v1682988695/expandcollapse-icon_2x.webp';
    const collapseUrl =
      'https://assets.fastcompany.com/image/upload/v1682988629/expand-collapse-icon_2x.webp';
    // const toogleButton = document.getElementsByClassName('toogle-button')[0];
    this.setState(
      prevState => ({
        resizePlayer: !prevState.resizePlayer,
        iconUrl: prevState.resizePlayer ? collapseUrl : expandUrl
      }),
      () => {
        if (this.state.resizePlayer) {
          // eslint-disable-next-line no-unused-expressions
          screenWidth >= 768 ? player.resize(768, 216) : player.resize(128, 72);
          // player.resize(128, 72);
        } else {
          // eslint-disable-next-line no-unused-expressions
          screenWidth >= 768
            ? player.resize(768, 432)
            : player.resize(390, 216);
          // player.resize(375, 210);
        }
      }
    );
  }

  onVideoLoad(event) {
    this.setState({
      videoTitle: get(event, 'item.title')
    });
  }

  onReady() {
    const { playerid } = this.props;
    const player = window.jwplayer(playerid);
    const showCaptions = player.getMute() ? 1 : 0;

    this.player = player;

    player.on('captionsList', ({ tracks }) => {
      if (showCaptions && tracks.length > 1) {
        player.setCurrentCaptions(1);
      }
    });

    // Set a flag based on ads playing or not
    player.on('adRequest', () => {
      this.setState({ adIsPlaying: true });
    });
    player.on('adComplete', () => {
      this.setState({
        ...this.state,
        adIsPlaying: false,
        adIsDone: true
      });
    });
    player.on('adSkipped', () => {
      this.setState({ adIsPlaying: false });
    });

    player.on('playlistItem', () => {
      this.customVideoMetadata = {
        playerDimensions: `${player.getContainer().offsetWidth}x${
          player.getContainer().offsetHeight
        }`,
        abgroup: window?._ash?.requestAbGroup // eslint-disable-line no-underscore-dangle
      };

      // Initialize play positions
      //
      this.lastKnownPlaybackPosition = 0;
      this.lastSystemClock = 0;
      this.playSent = false;
    });

    player.on('beforePlay', () => {
      if (player.getPosition() === 0) {
        if (!this.sessionStarted) {
          this.sessionStarted = true;
        }
      }
    });

    player.on('firstFrame', () => {
      // If haven't sent the play event then send it.  Note that it's possible the
      // play vent was sent when the AdStart event was signaled
      if (!this.playSent) {
        this.PlaySent = true;
      }
    });

    player.on('time', data => {
      const d = new Date();
      this.lastSystemClock = d.getTime();
      this.lastKnownPlaybackPosition = data.position;

      // custom code specific for this component
      const percentPlayed = Math.floor(data.position * 100 / data.duration);
      const marker = {};

      switch (percentPlayed) {
        case 1:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoStart) {
            // this.trackMoatVideoProgress('AdVideoStart', player, data);
            marker.AdVideoStart = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 25:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoFirstQuartile) {
            // this.trackMoatVideoProgress('AdVideoFirstQuartile', player, data);
            marker.AdVideoFirstQuartile = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 50:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoMidpoint) {
            // this.trackMoatVideoProgress('AdVideoMidpoint', player, data);
            marker.AdVideoMidpoint = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 75:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoThirdQuartile) {
            // this.trackMoatVideoProgress('AdVideoThirdQuartile', player, data);
            marker.AdVideoThirdQuartile = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        case 99:
          if (!this.state.calledMoatEventTrackerMarker.AdVideoComplete) {
            // this.trackMoatVideoProgress('AdVideoComplete', player, data);
            marker.AdVideoComplete = true;
            this.setState({
              calledMoatEventTrackerMarker: marker
            });
          }
          break;
        default:
          break;
      }
    });

    player.on('adTime', data => {
      const d = new Date();
      this.lastSystemClock = d.getTime();
      this.lastKnownPlaybackPosition = data.position;
    });

    player.on('seek', () => {});

    player.on('seeked', () => {
      this.lastKnownPlaybackPosition = player.getPosition(); // Save last known position
    });

    // // User paused the video
    // player.on('pause', e => {
    //   // custom code specific for this component
    //   this.trackMoatVideoProgress('AdPaused', player, e);
    // });

    // If the play event is fired and ads were playing,
    // then send the AdBreakComplete event.  There is
    // no ad playback end event
    //
    // player.on('play', e => {
    //   if (this.adsPlaying) {
    //     this.adsPlaying = false;
    //   }

    //   // Custom code specific for this component
    //   this.trackMoatVideoProgress('AdPlaying', player, e);
    // });

    player.on('complete', () => {
      // If an ad was playing (POST-ROLL), send the AdBreakComplete
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }

      this.resetSession();

      // Force player stop if user determined to be inactive.
      // FOR NOW: Commenting out so video playlist autoplays through.
      // TODO: move this to a more useful place?
      // if (!this.state.userIsActive) {
      //   player.stop();
      // }

      // custom code specific for this component
      this.setState({
        firstimp: false
      });
    });

    // Only send the buffer event if the session has been started
    // We don't send one just because the player loaded and pre-buffered
    player.on('buffer', () => {});
    player.on('bufferFull', () => {});

    player.on('error', () => {
      // error means the player stopped so reset the session
      this.resetSession();
    });

    player.on('setupError', () => {
      this.resetSession();
    });

    player.on('adImpression', data => {
      const adTitle = get(data, 'adtitle', 'unknown-title');
      const adWrapperCreativeId = get(
        data,
        'ima.ad.g.adWrapperCreativeIds[0]',
        'unknown-wrapper-creative-id'
      );
      const adWrapperId = get(
        data,
        'ima.ad.g.adWrapperIds[0]',
        'unknown-wrapper-id'
      );
      const adCreativeId = get(
        data,
        'ima.ad.g.creativeId',
        'unknown-creative-id'
      );
      const adName = `${adTitle} | ${adWrapperCreativeId} | ${adWrapperId} | ${adCreativeId}`;

      // Start the adBreak if needed
      this.adStartBreak(
        adName,
        this.adPosition,
        this.lastKnownPlaybackPosition
      );

      // Note: The documentation says that after the first ad in a Pre-roll
      // starts to play, the 'trackPlay' event should be sent.  But, it seems,
      // in practice, this does not work correctly.  The adStart event that is sent
      // above in the adImpression handler, automatically kicks off the trackPlay
      // event.  Thus, just set the gPlaySent flag to true, so it is not sent again
      //
      if (!this.playSent) {
        this.playSent = true;
      }
      // Set the flag that an Ad is playing
      //
      this.adsPlaying = true;

      // Track with Moat
      // this.trackMoat(player, data);
    });

    player.on('adPlay', () => {
      if (!this.playSent) {
        this.playSent = true;
      }
    });

    player.on('adStarted', () => {});

    player.on('adSkipped', () => {
      // Set the flag to false so the AdBreakComplete is not sent
      // unless a new ad starts playing
      this.adsPlaying = false;
    });

    player.on('adComplete', () => {});

    player.on('adBreakEnd', () => {
      // If an ad was playing send the AdBreakComplete
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }
    });

    player.on('remove', () => {
      // Close the session if it's still in progress
      if (this.sessionStarted) {
        this.resetSession();
      }
    });

    // player.on('adRequest', ad => {
    //   console.log('[ads] === mobile_video_module_reactjw player.on adRequest', ad);
    //   const adDataDFP = get(ad, 'ima.ad.g');
    //   const { adId, creativeId, title } = adDataDFP;

    //   const adDataForMoat = {
    //     level1:
    //       get(adDataDFP, 'advertiserName', `advertiser for unit: ${title}`) ||
    //       `advertiser for unit: ${title}`,
    //     level2: title,
    //     level3: adId,
    //     level4: creativeId,
    //     slicer1: 'fastcompany',
    //     slicer2: creativeId
    //   };

    //   this.setState({
    //     adDataForMoat,
    //     calledMoatEventTrackerMarker: {}
    //   });
    //   this.MoatApiReference = null;
    // });

    this.setMoatTracking(player);
  }

  // eslint-disable-next-line react/sort-comp
  showCollapseButton() {
    const collapseContainer = document.getElementsByClassName(
      'collapse-button-container'
    )[0];
    if (collapseContainer) {
      collapseContainer.style.display = 'block';
    }
  }

  onPlay() {
    this.showCollapseButton();
  }

  onAdComplete() {
    this.showCollapseButton();
  }

  onAdSkipped() {
    this.showCollapseButton();
  }

  setMoatTracking(player) {
    if (canUseDOM) {
      if (window.moatjw) {
        player.on('adImpression', event => {
          window.moatjw.add({
            partnerCode: 'mansuetojwint127635890115',
            player,
            adImpressionEvent: event
          });
        });
      }
    }
  }

  resetSession() {
    this.sessionStarted = false; // Will need to reinitialize for next video
    this.playSent = false;
  }

  adStartBreak() {
    if (!this.adsPlaying) {
      this.adsPlaying = true;
      this.adPosition = 0;
    } else {
      this.adPosition = this.adPosition + 1; // Just increase the adPosition value
    }
  }

  // trackMoatVideoProgress(eventType, player, e) {
  //   const MoatTrackingPartnerCode = 'mansuetojwcontent613342534978';

  //   if (!this.MoatApiReference) {
  //     this.MoatApiReference = window.initMoatTracking(
  //       this.videoContainerRef.current,
  //       this.state.adDataForMoat,
  //       e.duration,
  //       MoatTrackingPartnerCode,
  //       null
  //     );
  //   }

  //   this.MoatApiReference.dispatchEvent({
  //     type: eventType,
  //     adVolume: player.getMute() ? 0 : (player.getVolume() / 100).toFixed(1)
  //   });
  // }

  generatePreRoll() {
    const { data, type, pos, readTime } = this.props;
    const customparams = get(this.props, 'customparams', false);

    if (!Cookies.get('dailycookie') && !this.state.alreadySawFirstImpression) {
      this.setState({
        firstimp: true,
        alreadySawFirstImpression: true
      });
    }

    return new GoogleIMAGenerator(
      get(data, 'title'),
      type,
      'preroll_rightrail',
      readTime,
      pos,
      this.state.firstimp,
      customparams,
      true
    ).adTag;
  }

  render() {
    const { playerid, playlistId } = this.props;

    if (this.state.windowWidth >= 1024) {
      return null;
    }

    return (
      <div
        className={`mobile-video-module ${
          this.state.resizePlayer ? 'resize' : ''
        }`}
        id="FCGlobalPlayerFrame"
      >
        <ModuleHeader
          noSlug={this.props.noSlug}
          slugName={this.props.slugName}
          adIsPlaying={this.state.adIsPlaying}
          adIsDone={this.state.adIsDone}
          onPlaySent={this.PlaySent}
          videoTitle={this.state.videoTitle}
          hideMobileVideoPlayer={this.props.hideMobileVideoPlayer}
          toggleVideoPlayer={this.toggleVideoPlayer}
          iconUrl={this.state.iconUrl}
        />

        <div
          className="Mobile-video-module__player-container"
          ref={this.videoContainerRef}
        >
          <Video
            playerId={playerid}
            customProps={{
              sharing: { link: 'https://www.fastcompany.com/videos' }
            }}
            playerScript={`https://content.jwplatform.com/libraries/${playerid}.js`}
            playlist={`//content.jwplatform.com/feeds/${playlistId}.json`}
            generatePrerollUrl={this.generatePreRoll}
            onReady={this.onReady}
            onPlay={this.onPlay}
            onAdComplete={this.onAdComplete}
            onVideoLoad={this.onVideoLoad}
            onError={event => {
              log.error(
                'Mobile ReactJW Video Player Error',
                get(event, 'message')
              );
            }}
            onSetupError={event => {
              log.error(
                'Mobile ReactJW Video Player Error',
                get(event, 'message')
              );
            }}
            playInView={true}
            isMuted={true}
            isAutoPlay={true}
            playInViewPercentage={100}
            aspectRatio="16:9"
            useMultiplePlayerScripts={true}
          />
        </div>
      </div>
    );
  }
}

MobileVideoReactJW.defaultProps = {
  data: {},
  slugName: 'Watch More From Fast Company',
  readTime: null,
  hideMobileVideoPlayer() {},
  noSlug: false,
  initialTitle: null
};

MobileVideoReactJW.propTypes = {
  data: PropTypes.shape({}),
  playerid: PropTypes.string.isRequired,
  playlistId: PropTypes.string.isRequired,
  slugName: PropTypes.string,
  type: PropTypes.string.isRequired,
  pos: PropTypes.string.isRequired,
  readTime: PropTypes.string,
  hideMobileVideoPlayer: PropTypes.func,
  noSlug: PropTypes.bool,
  initialTitle: PropTypes.string
};
