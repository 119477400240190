/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from './media';
import Title from './title';
import Advertorial from '../../advertorial/advertorial';
import get from 'lodash/get';
import ByLine from './by_line';
import { ShareButtonRedesign } from '../../../fc-components/main/components';

export default class Header extends Component {
  shouldComponentUpdate() {
    return false;
  }

  renderAdvertorial() {
    const { advertorial, tags } = this.props;

    if (advertorial) {
      return <Advertorial tags={tags} />;
    }

    return null;
  }

  renderDeck() {
    const { post } = this.props;
    const formatDate = date => new Date(date).toDateString().slice(4);
    const finalDate = get(post, 'datePublished', '');
    const allowedLayouts = ['long', 'hero', 'vertical', 'redesign'];
    const postExcerpt = get(post, 'excerpt', '');
    const layout = get(post, 'layout', '');

    if (
      allowedLayouts.indexOf(layout) > -1 ||
      post.id === 90282450 ||
      post.id === 90282460 ||
      post.id === 90282468 ||
      post.id === 90455507 ||
      post.id === 90455554 ||
      post.id === 90459733 ||
      post.id === 90459740
    ) {
      return (
        <Fragment>
          <h2
            className="post__deck"
            dangerouslySetInnerHTML={{ __html: postExcerpt }}
          />
          {layout === 'redesign' ? (
            <div className="header-grid">
              <div className="left">
                <div className="byline">
                  <ByLine post={post} specialRedesignArticle />
                </div>
                <div className="pubdate">{formatDate(finalDate)}</div>
              </div>
              <div className="right">
                <ShareButtonRedesign
                  textToShare={post.title}
                  uriToShare={post.link}
                />
              </div>
            </div>
          ) : null}
        </Fragment>
      );
    }

    return null;
  }

  renderMedia(props) {
    const { config, post, isVideoPost, isFirstPost, insert } = props;
    const cerosEmbed = get(post, 'customFields.ceros_embed', false);
    const verticalImg = get(post, 'customFields.verticalPosterImage', false);

    if (cerosEmbed) {
      return (
        <React.Fragment>
          <div dangerouslySetInnerHTML={{ __html: cerosEmbed }} />
          {verticalImg && (
            <Media
              config={config}
              post={post}
              isVideoPost={isVideoPost}
              isFirstPost={isFirstPost}
              noLazy={true}
            />
          )}
        </React.Fragment>
      );
    }
    return insert ? (
      ''
    ) : (
      <Media
        config={config}
        post={post}
        isVideoPost={isVideoPost}
        isFirstPost={isFirstPost}
        noLazy={true}
      />
    );
  }

  render() {
    const {
      config,
      tags,
      post,
      isFirstPost,
      live,
      insert,
      supertags
    } = this.props;
    return (
      <header className="post__header">
        <div
          className={
            insert
              ? 'post__header-text article__preview__title'
              : 'post__header-text'
          }
        >
          <div className="post__header__inner_wrapper">
            <Title
              config={config}
              isFirstPost={isFirstPost}
              post={post}
              tags={tags}
              live={live}
              insert={insert}
              supertags={supertags}
            />
            <div className={insert ? 'article__preview__deck' : ''}>
              {this.renderDeck()}
            </div>
          </div>
        </div>
        {this.renderMedia(this.props)}
      </header>
    );
  }
}

Header.defaultProps = {
  insert: false,
  supertags: []
};

Header.propTypes = {
  config: PropTypes.shape({}).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  advertorial: PropTypes.bool.isRequired,
  post: PropTypes.shape({}).isRequired,
  isFirstPost: PropTypes.bool.isRequired,
  live: PropTypes.bool.isRequired,
  insert: PropTypes.bool,
  supertags: PropTypes.arrayOf(PropTypes.string)
};
