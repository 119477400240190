import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import Video from 'react-jw-player';
import { canUseDOM } from 'exenv';
import FCSlider from '../../fc_slider/fc_slider';
import Poster from '../../poster/poster';
import { imageSizesLookup } from '../../../../utils/cloudinary_asset_util';
import GoogleIMAGenerator from '../../video_module/utils/video_module_ads';
import FloatingPlayer from '../../floating_player/floating_player';

let scriptjs = false;
let ifvisible = false;

if (canUseDOM) {
  // eslint-disable-next-line global-require
  scriptjs = require('scriptjs');
  // eslint-disable-next-line global-require
  ifvisible = require('ifvisible.js');
}

export default class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsActive: true,
      isFloating: false
    };
    const { config } = props;

    this.playerScriptId = !isEmpty(get(props, 'post.posterVideo', ''))
      ? get(props, 'post.playerScriptId')
      : 'QV73eWiB';
    this.player = undefined;

    // variable init
    this.adPosition = 0;
    this.lastKnownPlaybackPosition = 0;
    this.lastSystemClock = 0;
    this.mediaInfo = {};
    this.customVideoMetadata = {};

    // flags
    this.sessionStarted = false;
    this.playSent = false;
    this.adsPlaying = false;

    // function bindings
    this.generatePreRoll = this.generatePreRoll.bind(this);
    this.trackMoat = this.trackMoat.bind(this);
    this.onReady = this.onReady.bind(this);

    if (
      get(props, 'post.posterVideo.feedId') &&
      get(config, 'featureFlags.floatingVideo')
    ) {
      this.setScrollEvents = throttle(() => {
        const { scrollY } = this.window;
        this.checkIfScrollPassedVideoPlayer(scrollY);
      }, 200);
    }

    this.checkIfScrollPassedVideoPlayer = this.checkIfScrollPassedVideoPlayer.bind(
      this
    );
  }

  componentDidMount() {
    if (scriptjs) {
      const { post, isVideoPost } = this.props;
      const { posterVideo } = post;

      this.window = window;
      this.window.addEventListener('scroll', this.setScrollEvents);

      if (!isEmpty(posterVideo) || isVideoPost) {
        // Set up activity tracker, 10s = idle
        if (ifvisible) {
          ifvisible.setIdleDuration(10);
          ifvisible.on('idle', () => {
            this.setState({
              userIsActive: false
            });
          });

          ifvisible.on('wakeup', () => {
            this.setState({
              userIsActive: true
            });
          });
        }
      }
    }
  }

  componentWillUnmount() {
    if (canUseDOM) {
      if (window.moatjw) {
        window.moatjw.removeAll();
      }

      if (ifvisible) {
        ifvisible.off();
      }

      this.window.removeEventListener('scroll', this.setScrollEvents);
    }
  }

  onReady() {
    const { post } = this.props;
    const playerid = get(post, 'playerScriptId');
    const player = window.jwplayer(playerid);
    const caption = player.getMute() ? 1 : 0;

    this.player = player;

    player.setCurrentCaptions(caption);

    player.on('playlistItem', () => {
      const { config } = this.props;

      this.customVideoMetadata = {
        playerDimensions: `${player.getContainer().offsetWidth}x${
          player.getContainer().offsetHeight
        }`,
        abgroup: get(config, 'abgroup', 'null')
      };

      // Initialize play positions
      //
      this.lastKnownPlaybackPosition = 0;
      this.lastSystemClock = 0;
      this.playSent = false;
    });

    player.on('beforePlay', () => {
      if (player.getPosition() === 0) {
        if (!this.sessionStarted) {
          this.sessionStarted = true;
        }
      }
    });

    player.on('firstFrame', () => {
      // If haven't sent the play event then send it.  Note that it's possible the
      // play vent was sent when the AdStart event was signaled
      if (!this.playSent) {
        this.PlaySent = true;
      }
    });

    player.on('time', data => {
      const d = new Date();
      this.lastSystemClock = d.getTime();
      this.lastKnownPlaybackPosition = data.position;
    });

    player.on('adTime', data => {
      const d = new Date();
      this.lastSystemClock = d.getTime();
      this.lastKnownPlaybackPosition = data.position;
    });

    player.on('seek', () => {});

    player.on('seeked', () => {
      this.lastKnownPlaybackPosition = player.getPosition(); // Save last known position
    });

    // User paused the video
    player.on('pause', () => {});

    // If the play event is fired and ads were playing,
    // then send the AdBreakComplete event.  There is
    // no ad playback end event
    //
    player.on('play', () => {
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }
    });

    player.on('complete', () => {
      // If an ad was playing (POST-ROLL), send the AdBreakComplete
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }

      this.resetSession();

      // Force player stop if user determined to be inactive
      if (!this.state.userIsActive) {
        player.stop();
      }
    });

    // Only send the buffer event if the session has been started
    // We don't send one just because the player loaded and pre-buffered
    player.on('buffer', () => {});
    player.on('bufferFull', () => {});

    player.on('error', () => {
      // error means the player stopped so reset the session
      this.resetSession();
    });

    player.on('setupError', () => {
      this.resetSession();
    });

    player.on('adImpression', data => {
      const adTitle = get(data, 'adtitle', 'unknown-title');
      const adWrapperCreativeId = get(
        data,
        'ima.ad.g.adWrapperCreativeIds[0]',
        'unknown-wrapper-creative-id'
      );
      const adWrapperId = get(
        data,
        'ima.ad.g.adWrapperIds[0]',
        'unknown-wrapper-id'
      );
      const adCreativeId = get(
        data,
        'ima.ad.g.creativeId',
        'unknown-creative-id'
      );
      const adName = `${adTitle} | ${adWrapperCreativeId} | ${adWrapperId} | ${adCreativeId}`;

      // Start the adBreak if needed
      this.adStartBreak(
        adName,
        this.adPosition,
        this.lastKnownPlaybackPosition
      );

      // Note: The documentation says that after the first ad in a Pre-roll
      // starts to play, the 'trackPlay' event should be sent.  But, it seems,
      // in practice, this does not work correctly.  The adStart event that is sent
      // above in the adImpression handler, automatically kicks off the trackPlay
      // event.  Thus, just set the gPlaySent flag to true, so it is not sent again
      //
      if (!this.playSent) {
        this.playSent = true;
      }
      // Set the flag that an Ad is playing
      //
      this.adsPlaying = true;

      // Track with Moat
      this.trackMoat(player, data);
    });

    player.on('adPlay', () => {
      if (!this.playSent) {
        this.playSent = true;
      }
    });

    player.on('adStarted', () => {});

    player.on('adSkipped', () => {
      // Set the flag to false so the AdBreakComplete is not sent
      // unless a new ad starts playing
      this.adsPlaying = false;
    });

    player.on('adComplete', () => {});

    player.on('adBreakEnd', () => {
      // If an ad was playing send the AdBreakComplete
      if (this.adsPlaying) {
        this.adsPlaying = false;
      }
    });

    player.on('remove', () => {
      // Close the session if it's still in progress
      if (this.sessionStarted) {
        this.resetSession();
      }
    });
  }

  resetSession() {
    this.sessionStarted = false; // Will need to reinitialize for next video
    this.playSent = false;
  }

  adStartBreak() {
    if (!this.adsPlaying) {
      this.adsPlaying = true;
      this.adPosition = 0;
    } else {
      this.adPosition = this.adPosition + 1; // Just increase the adPosition value
    }
  }

  checkIfScrollPassedVideoPlayer(scrollY) {
    const { videoPlayerWrapper } = this;
    const postContainerLength = window.document.getElementsByClassName(
      'post__body'
    ).length;
    const postContainer = window.document.getElementsByClassName('post__body')[
      postContainerLength - 1
    ];

    if (!videoPlayerWrapper || !postContainer) {
      return;
    }

    const containerHeight = postContainer.clientHeight;

    const { top } = videoPlayerWrapper.getBoundingClientRect();
    const offset = Math.floor(top + videoPlayerWrapper.clientHeight * 2);

    if (scrollY >= offset && scrollY <= containerHeight) {
      this.setState({
        isFloating: true
      });
    } else if (
      scrollY <= offset - videoPlayerWrapper.clientHeight * 2 ||
      scrollY >= containerHeight
    ) {
      this.setState({
        isFloating: false
      });
    }

    // if (scrollY >= containerHeight) {
    //   this.window.removeEventListener('scroll', this.setScrollEvents);
    // }
  }

  trackMoat(player, event) {
    if (canUseDOM) {
      if (window.moatjw) {
        window.moatjw.add({
          partnerCode: 'mansuetojwint127635890115',
          player,
          adImpressionEvent: event
        });
      }
    }
  }

  generatePreRoll(video) {
    return new GoogleIMAGenerator(
      video.title,
      'article',
      null,
      null,
      null,
      null,
      null,
      true
    ).adTag;
  }

  render() {
    const { config, post, isVideoPost, isFirstPost, noLazy } = this.props;
    const {
      featured_image: featuredImage,
      posterSlideshow,
      posterVideo,
      posterIframe,
      title
    } = post;

    const apiEndpoint = get(config, 'apiEndpoint');
    const apiProtocol = get(config, 'protocol');

    if (!isEmpty(posterSlideshow)) {
      return <FCSlider slideShowData={posterSlideshow} title={title} />;
    }

    if (!isEmpty(posterVideo)) {
      const feed = get(posterVideo, 'feedId', '');
      const isAutoPlay = Boolean(isFirstPost);
      const playInView = Boolean(!isFirstPost);
      const isMuted = Boolean(!isFirstPost);

      if (
        (get(post, 'isVideoPermalink') || get(post, 'posterVideo.feedId')) &&
        !get(post, 'playerScriptId')
      ) {
        set(post, 'playerScriptId', 'QV73eWiB');
      }

      return (
        <FloatingPlayer
          floatingClassName="floating-player--is-floating"
          position="bottom-left"
          isFloating={this.state.isFloating}
        >
          <div
            className="poster-video-wrapper poster-video-wrapper--video-permalink"
            ref={el => {
              this.videoPlayerWrapper = el;
            }}
          >
            <Video
              playerId={get(post, 'playerScriptId')}
              playerScript={`https://content.jwplatform.com/libraries/${get(
                post,
                'playerScriptId'
              )}.js`}
              playlist={`${apiProtocol}${apiEndpoint}/api/v1/jw-feed/${feed}`}
              generatePrerollUrl={this.generatePreRoll}
              isAutoPlay={isAutoPlay}
              playInView={playInView}
              isMuted={isMuted}
              onReady={this.onReady}
              playInViewPercentage={100}
              aspectRatio="16:9"
              className="jwplayer--video-post"
            />
          </div>
        </FloatingPlayer>
      );
    }

    if (!isEmpty(posterIframe)) {
      const source = get(posterIframe, 'url');
      /* eslint-disable jsx-a11y/iframe-has-title */
      return (
        <div className="poster-video-wrapper">
          <iframe className="poster-video-wrapper__video" src={source} />
        </div>
      );
    }
    if (isVideoPost) {
      const isAutoPlay = Boolean(!isFirstPost);
      const playInView = Boolean(isFirstPost);
      return (
        <FloatingPlayer
          floatingClassName="floating-player--is-floating"
          position="bottom-right"
          isFloating={this.state.isFloating}
        >
          <div
            className="poster-video-wrapper poster-video-wrapper--video-permalink"
            ref={el => {
              this.videoPlayerWrapper = el;
            }}
          >
            <Video
              playerId={get(post, 'mediaid')}
              playerScript="https://content.jwplatform.com/libraries/QV73eWiB.js"
              playlist={`${apiProtocol}${apiEndpoint}/api/v1/jw-feed/${get(
                post,
                'mediaid'
              )}`}
              generatePrerollUrl={this.generatePreRoll}
              isAutoPlay={isAutoPlay}
              playInView={playInView}
              onReady={this.onReady}
              playInViewPercentage={100}
              aspectRatio="16:9"
              className="jwplayer--video-post"
            />
          </div>
        </FloatingPlayer>
      );
    }

    const posterSrc = get(featuredImage, 'source', '');
    const verticalImg = get(
      post,
      'customFields.verticalPosterImage.url',
      false
    );
    const posterCaption =
      get(post, 'customFields.verticalPosterImage.caption', false) ||
      get(featuredImage, 'caption', '');

    const assetSizes = get(
      imageSizesLookup,
      post.layout === 'hero' ? 'hero' : 'poster'
    );
    const metadata = {
      title,
      assetSizes,
      featured_image: { source: posterSrc, verticalImg },
      noLazy: noLazy || false
    };

    if (!posterSrc) {
      return null;
    }

    return (
      <div
        className={`post__poster-wrapper post__poster-wrapper--${post.layout} ${
          posterCaption ? '' : 'post__poster-wrapper--no-caption'
        }`}
      >
        <Poster
          metadata={metadata}
          className="post-featured"
          isVideo={post.hasPosterVideo}
          isFirstPost={isFirstPost}
        />

        {posterCaption ? (
          <div
            className="post__featured-caption"
            dangerouslySetInnerHTML={{ __html: posterCaption }}
          />
        ) : null}
      </div>
    );
  }
}

Media.defaultProps = {
  noLazy: false
};

Media.propTypes = {
  config: PropTypes.shape({}).isRequired,
  post: PropTypes.shape({}).isRequired,
  isVideoPost: PropTypes.bool.isRequired,
  isFirstPost: PropTypes.bool.isRequired,
  noLazy: PropTypes.bool
};
