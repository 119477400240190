import { canUseDOM } from 'exenv';

function scrollLoop(element, duration, navHeight) {
  if (duration <= 0) return;

  const difference = element.offsetTop - document.body.scrollTop - navHeight;
  const perTick = difference / duration * 10;

  setTimeout(() => {
    // eslint-disable-next-line no-param-reassign
    document.body.scrollTop += perTick;
    return scrollLoop(element, duration - 10, navHeight);
  }, 10);
}

export default function scrollToEl(querySelector, duration) {
  if (canUseDOM && querySelector && duration && duration > 0) {
    const el = document.querySelector(`${querySelector}:not(.scrolled)`);
    const navHeight = window.innerWidth > 640 ? 90 : 0;
    if (el) {
      scrollLoop(el, duration, navHeight);
      el.classList.add('scrolled');
    }
  }
}
