/* eslint-disable react/prop-types, camelcase, no-case-declarations, no-undef */
import React from 'react';
import { isAdvertorial, isCustom } from '../../advertorial/advertorial';

const Byline = ({
  bylineAttribution = '',
  live = false,
  specialRedesignArticle = false,
  post: {
    author = {},
    tags = [],
    readTime = null,
    customFields: {
      existingAuthors = false,
      guestAuthors = false,
      hide_byline = false
    }
  }
}) => {
  const bylineContent = [];
  const postTags = tags.map(tag => tag.slug);

  const isFCW = author.slug === 'fastco-works';
  const isFCEB = postTags.includes('fast-company-executive-board');

  const hideReadTime = live || isAdvertorial(postTags) || isCustom(postTags);
  const hideByline = hide_byline;

  const formatName = name => name.trim();

  // If article has a custom byline, is not FastCo Works, and is not Fast Company Executive Board w/an author with a custom URL attached.
  if (bylineAttribution && !isFCW && !(isFCEB && author.url)) {
    bylineContent.push(bylineAttribution);
  }

  // If article is FastCo Works. May include a custom byline.
  if (isFCW) {
    bylineContent.push(
      `<a href="/user/fastco-works">${
        guestAuthors
          ? formatName(guestAuthors[0].authorName)
          : formatName(bylineAttribution) || formatName(author.name)
      }</a>`
    );
  }

  // If article is Fast Company Executive Board w/an author with a custom URL attached.
  if (isFCEB && author.url) {
    bylineContent.push(
      `<a href="${author.url}" target="_blank">${formatName(author.name)}</a>`
    );
  }

  // If author is entered into the Existing Authors field.
  if (existingAuthors) {
    existingAuthors.forEach(existingAuthor => {
      bylineContent.push(
        `<a href="/user/${existingAuthor.user_nicename}">${formatName(
          existingAuthor.display_name
        )}</a>`
      );
    });
  }

  // If author is entered into the Guest Authors field.
  if (!!guestAuthors && !isFCW) {
    guestAuthors.forEach(guestAuthor => {
      bylineContent.push(formatName(guestAuthor.authorName));
    });
  }

  // This effectively catches if none of the above criteria are met, renders the standard author name & author URL with slug.
  if (
    !existingAuthors &&
    !guestAuthors &&
    !bylineAttribution &&
    !isFCW &&
    !isFCEB
  ) {
    bylineContent.push(
      `<a href="/user/${author.slug}">${formatName(author.name)}</a>`
    );
  }

  // Determine how the byline should be formatted based on number of authors.
  const joinText = (() => {
    switch (bylineContent.length) {
      case 1:
        return '';
      case 2:
        return ' and ';
      default:
        const lastItem = bylineContent[bylineContent.length - 1];
        bylineContent[bylineContent.length - 1] = `and ${lastItem}`;
        return ', ';
    }
  })();

  const bylineText = `<cite class="${
    bylineAttribution ? 'post__no_highlight' : 'post__by'
  }">${!specialRedesignArticle ? 'By ' : ''}${bylineContent.join(
    joinText
  )}</cite>`;

  const bylineTextLength = bylineText.replace(/<[^>]*>/gi, '').length;

  const readTimeText = `<span class="post__readTime ${
    bylineTextLength > 46 ? 'nextLine' : ''
  }">${readTime} Read</span>`;

  return (
    <div
      className="post__byline"
      dangerouslySetInnerHTML={{
        __html: `${!hideByline ? bylineText : ''}${
          !hideReadTime ? readTimeText : ''
        }`
      }}
    />
  );
};

export default Byline;
