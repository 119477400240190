import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

class FloatingPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFloating: props.isFloating
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextPropIsFloating = get(nextProps, 'isFloating');

    if (nextPropIsFloating !== this.state.isFloating) {
      this.setState({
        isFloating: nextPropIsFloating
      });
    }
  }

  render() {
    const { floatingClassName, position, children } = this.props;

    const floatingPlayerClass = this.state.isFloating
      ? `${floatingClassName}-${position}`
      : '';

    return (
      <div className={`floating-player ${floatingPlayerClass}`}>
        <div className="floating-player__player-container">{children}</div>
      </div>
    );
  }
}

FloatingPlayer.defaultProps = {
  isFloating: false,
  position: 'bottom-right',
  floatingClassName: 'floating-player--is-floating'
};

FloatingPlayer.propTypes = {
  isFloating: PropTypes.bool,
  floatingClassName: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default FloatingPlayer;
