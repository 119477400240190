import React from 'react';
import PropTypes from 'prop-types';

const ModuleHeader = ({
  noSlug,
  slugName,
  adIsPlaying,
  adIsDone,
  onPlaySent,
  videoTitle,
  toggleVideoPlayer,
  iconUrl
}) => {
  const playIconUrl =
    'https://assets.fastcompany.com/image/upload/v1684474060/video-icon.svg';
  return (
    <div className="mobile-video-module-header">
      {!noSlug && <div className="component-slug">{slugName}</div>}
      <div className="play-icon-container">
        <img
          class="play-icon"
          src={playIconUrl}
          alt="playIcon"
          loading="lazy"
        />
      </div>
      <div className="video-title">
        {adIsPlaying && <span>Next: </span>}
        {videoTitle}
      </div>
      <div className="collapse-button-container" onClick={toggleVideoPlayer}>
        <img
          className={`toogle-button ${
            adIsDone || onPlaySent ? 'show-button' : ''
          }`}
          src={iconUrl}
          alt="collapseBtn"
          loading="lazy"
          width={30}
          height={30}
        />
      </div>
      {/* <div
        className="close-button"
        onClick={() => {
          hideMobileVideoPlayer();
        }}
      >
        <img
          src="https://assets.fastcompany.com/multisite_files/fastcompany/static/icons/mvp-close-button_new.svg"
          alt="close"
        />
      </div> */}
    </div>
  );
};

ModuleHeader.defaultProps = {
  noSlug: false,
  slugName: '',
  adIsPlaying: false,
  adIsDone: false,
  onPlaySent: false,
  videoTitle: '',
  toggleVideoPlayer: () => {}
};

ModuleHeader.propTypes = {
  noSlug: PropTypes.bool,
  slugName: PropTypes.string,
  adIsPlaying: PropTypes.bool,
  adIsDone: PropTypes.bool,
  onPlaySent: PropTypes.bool,
  videoTitle: PropTypes.string,
  iconUrl: PropTypes.string.isRequired,
  toggleVideoPlayer: PropTypes.func
};

export default ModuleHeader;
