import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import get from 'lodash/get';
import PackageNavData from './package_nav_data';
/* eslint react/no-did-mount-set-state: 0 */

export default class PackageNav extends Component {
  constructor(props) {
    super(props);

    this.navigate = this.navigate.bind(this);

    this.state = {
      categories: []
    };

    this.currentArticle = this.props.url
      ? this.props.url.replace(
          /^.+:\/\/((www.)?fastcompany\.com|fastcompany\.local:8880)\//,
          '/'
        )
      : '';

    this.packageName = this.props.navData.name;
    this.packageUrl = this.props.navData.url;
  }

  componentDidMount() {
    if (this.props.navData.hardCoded) {
      const categories = PackageNavData[this.packageName];
      this.setState({ categories });
    } else {
      axios
        .get(
          `https://fc-api.fastcompany.com/api/v1/id/fastcompany/${
            this.props.navData.articleId
          }` // THIS NEEDS TO CHANGE
        )
        .then(data => {
          const post = get(data, 'data.posts[0]', {});
          const mcpData = get(post, 'acf.mcp_list', {});

          const categories = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const property in mcpData) {
            if (property.includes('cat_entries_')) {
              if (mcpData[property].display_name === '') {
                // eslint-disable-next-line no-continue
                continue;
              }
              categories.push(mcpData[property]);
            }
          }

          this.setState({ categories });
        });
    }
  }

  navigate(e) {
    window.location.href = e.target.value;
  }

  render() {
    const categories = get(this.state, 'categories', []);

    return (
      <nav className="package-nav">
        <div className="header">
          <a href={this.packageUrl}>{this.packageName}</a>
        </div>

        <select value={this.currentArticle} onChange={this.navigate}>
          {categories.map(category => {
            const label = get(category, 'display_name', '');
            const entries = get(category, 'entries', []);

            return (
              <optgroup label={label.toUpperCase()}>
                {entries.map(entry => {
                  if (entry.url && entry.name) {
                    return (
                      <option
                        value={entry.url.replace(
                          /^.+:\/\/((www.)?fastcompany\.com|fastcompany\.local:8880)\//,
                          '/'
                        )}
                      >
                        {entry.name}
                      </option>
                    );
                  }
                  return null;
                })}
              </optgroup>
            );
          })}
        </select>
      </nav>
    );
  }
}

PackageNav.propTypes = {
  url: PropTypes.string.isRequired,
  navData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    hardCoded: PropTypes.bool.isRequired,
    articleId: PropTypes.string
  }).isRequired
};
