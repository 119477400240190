import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { popupCenter, trackSocial } from '../../../utils/share_util';
import mergeContent from '../../../utils/content_util';

export default class Update extends Component {
  constructor(props) {
    super(props);

    this.shareUpdate = this.shareUpdate.bind(this);
  }

  shareUpdate(event) {
    const { parentPost, site, update } = this.props;
    const status = encodeURIComponent(parentPost.title);
    const updateUrl = `https%3A//www.${site}.com/${parentPost.id}/${
      parentPost.slug
    }%23update-${update.id}`;
    const hashtags = parentPost.hashtags.join(',');
    const url = `https://twitter.com/share?url=${updateUrl}&text=${status}&hashtags=${hashtags}`;

    popupCenter(event, url, 560, 725);
    trackSocial('Twitter', 'Tweet');
  }

  render() {
    const { update } = this.props;
    this.content = mergeContent(update.content);

    return (
      <div key={update.id} id={`update-${update.id}`} className="live__update">
        <div className="live__eyebrow">
          <span>{update.author.name}</span>
          <span>{update.dateFormatted}</span>
          <span>{update.timePublished}</span>
          <span className="live__share" onClick={this.shareUpdate}>
            Share This Update
          </span>
        </div>
        <div
          className="live__content post__article"
          dangerouslySetInnerHTML={{
            __html: this.content
          }}
        />
      </div>
    );
  }
}

Update.propTypes = {
  update: PropTypes.shape({}).isRequired,
  parentPost: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired
};
