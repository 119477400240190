import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarketoForm from '../../MarketoForm/MarketoForm';

const WorkSmarterFooter = ({ type, assetId, lazy }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div
      className={`newsletter__widget newsletter__widget__${type} widget__state--${
        submitted ? 'submitted' : 'init'
      }`}
    >
      <header className="header">
        <img
          alt="Work Smarter Newsletter logo"
          className="header__logo"
          src="https://assets.fastcompany.com/image/upload/v1684431897/newsletters/work-smarter-logo-horizontal.svg"
        />
        {!submitted && (
          <div>
            <span className="highlight">
              Work Smarter, not harder. Get our editors' tips and stories
              delivered weekly.
            </span>
          </div>
        )}
      </header>
      {submitted ? (
        <p className="submission success__message">
          <span className="text-primary">
            Thank you! Look out for your first email!
          </span>
        </p>
      ) : (
        <MarketoForm
          assetId={assetId}
          name="inline-newsletter-form"
          onSubmitHandler={setSubmitted}
          lazy={lazy}
        />
      )}
      <footer>
        <a
          rel="noopener noreferrer"
          href="https://www.mansueto.com/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

WorkSmarterFooter.displayName = 'WorkSmarterFooter';

WorkSmarterFooter.propTypes = {
  type: PropTypes.string.isRequired,
  assetId: PropTypes.number,
  lazy: PropTypes.bool
};

WorkSmarterFooter.defaultProps = {
  assetId: 1314,
  lazy: false
};

export default WorkSmarterFooter;
