import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import get from 'lodash/get';
import isString from 'lodash/isString';
import striptags from 'striptags';
import { canUseDOM } from 'exenv';
import { imageSizesLookup } from '../../../utils/cloudinary_asset_util';
import FCImage from '../image/image';
import GIFConverter from '../gif_converter/gif_converter';
import Arrow from '../arrow/arrow';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import { sendComscoreCandidatePing } from '../../../client/utils/third_party_trackers/comscore';

const prevArrow = (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 41 40">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#CCC"
        d="M.47 20c0 11.045 8.952 20 20 20 11.045 0 20-8.955 20-20s-8.955-20-20-20C9.42 0 .47 8.955.47 20z"
      />
      <path fill="#000" d="M12.527 19.998l7.5 3.75v-7.5" />
      <path stroke="#000" d="M19.5 20h8.438" />
    </g>
  </svg>
);

const nextArrow = (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 41 40">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#CCC"
        d="M.47 20c0 11.045 8.952 20 20 20 11.045 0 20-8.955 20-20s-8.955-20-20-20C9.42 0 .47 8.955.47 20z"
      />
      <path fill="#000" d="M27.027 19.998l-7.5-3.75v7.5" />
      <path stroke="#000" d="M12.5 20h8.438" />
    </g>
  </svg>
);

const settings = {
  prevArrow: <Arrow markup={prevArrow} />,
  nextArrow: <Arrow markup={nextArrow} />,
  accessibility: true,
  fade: true,
  swipe: false,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        fade: false,
        swipe: true
      }
    }
  ]
};

class FCSlider extends Component {
  constructor(props) {
    super();
    this.counter = 0;
    this.onBeforeChange = this.onBeforeChange.bind(this);
    this.onAfterChange = this.onAfterChange.bind(this);
    this.captions = props.slideShowData.map(
      (slideData, index, arr) =>
        `<span class="header-slideshow__slide-curr">${index +
          1}</span><span class="header-slideshow__slide-total">/${
          arr.length
        }</span> ${slideData.caption ? slideData.caption : ''}`
    );
    this.mediaItems = props.slideShowData;
    this.state = {
      readyToMount: false
    };
  }
  componentDidMount() {
    this.applyImagesOrientations();
    this.changeCaption(0);
  }

  onBeforeChange(currentSlideIndex, nextSlideIndex) {
    if (this.props.location === 'header') {
      this.trackSlideshowEvent(currentSlideIndex, nextSlideIndex);
    }
  }

  onAfterChange(index) {
    this.changeCaption(index);
  }

  applyImagesOrientations() {
    return this.mediaItems.forEach((item, i, arr) => {
      if (item.imgUrl && isString(item.imgUrl)) {
        const image = new Image();
        let src = item.imgUrl
          .replace(
            'wp-cms-fastcompany-com.s3.amazonaws.com',
            'images.fastcompany.net/image/upload/wp-cms'
          )
          .replace('upload/', 'upload/w_100,q_auto:low,f_auto,fl_lossy/')
          .replace('fast-company-res.cloudinary.com', 'images.fastcompany.net')
          .replace('http://', 'https://');

        if (/.gif$/.test(src)) {
          src = src.replace('f_auto', 'f_webp');
        }

        image.src = src;

        image.onload = function assignImageOrientation() {
          const { width, height } = image;

          if (height >= width) {
            // eslint-disable-next-line no-param-reassign
            item.modifier = 'portrait';
          } else {
            // eslint-disable-next-line no-param-reassign
            item.modifier = 'landscape';
          }

          if (i === arr.length - 1) {
            this.setState({ readyToMount: true });
          }
        }.bind(this);
      } else if (i === arr.length - 1) {
        this.setState({ readyToMount: true });
      }
    });
  }

  trackSlideshowEvent(currentSlideIndex, nextSlideIndex) {
    if (canUseDOM) {
      const trackCurrent = currentSlideIndex + 1;
      if (currentSlideIndex !== undefined && nextSlideIndex !== undefined) {
        if (
          currentSlideIndex === 0 &&
          currentSlideIndex - nextSlideIndex < -1
        ) {
          trackGAEvent({
            eventCategory: 'Interaction',
            eventAction: 'slideshow arrow:previous',
            eventLabel: trackCurrent
          });
          sendComscoreCandidatePing();
        } else {
          if (currentSlideIndex - nextSlideIndex > 1) {
            trackGAEvent({
              eventCategory: 'Interaction',
              eventAction: 'slideshow arrow:next',
              eventLabel: trackCurrent
            });
            sendComscoreCandidatePing();
          }
          if (
            nextSlideIndex - currentSlideIndex < 0 &&
            Math.abs(nextSlideIndex - currentSlideIndex) === 1
          ) {
            trackGAEvent({
              eventCategory: 'Interaction',
              eventAction: 'slideshow arrow:previous',
              eventLabel: trackCurrent
            });
            sendComscoreCandidatePing();
          }
          if (
            nextSlideIndex - currentSlideIndex > 0 &&
            Math.abs(nextSlideIndex - currentSlideIndex) === 1
          ) {
            trackGAEvent({
              eventCategory: 'Interaction',
              eventAction: 'slideshow arrow:next',
              eventLabel: trackCurrent
            });
            sendComscoreCandidatePing();
          }
        }
      }
    }
  }

  changeCaption(index) {
    const hasCaption = !!this.captions[index];

    this.setState({
      currentCaption: `${hasCaption ? this.captions[index] : ''}`
    });
  }

  render() {
    const slideShowData = this.mediaItems || [];
    const { location } = this.props;

    settings.className = `${location}-slideshow`;

    return this.state.readyToMount ? (
      <div>
        {' '}
        <Slider
          {...settings}
          beforeChange={this.onBeforeChange}
          afterChange={this.onAfterChange}
        >
          {slideShowData.map(slideData => (
            <figure
              className={`${location}-slideshow__slide`}
              key={JSON.stringify(slideData)}
            >
              {' '}
              {/.gif$/.test(slideData.imgUrl) ? (
                <GIFConverter
                  src={get(slideData, 'imgUrl')}
                  assetSizes={imageSizesLookup.slider}
                  alt={
                    get(slideData, 'caption')
                      ? striptags(get(slideData, 'caption') || '')
                      : this.props.title
                  }
                />
              ) : (
                <FCImage
                  baseClass={`${location}-slideshow__image`}
                  modifier={get(slideData, 'modifier')}
                  src={get(slideData, 'imgUrl')}
                  assetSizes={imageSizesLookup.slider}
                  alt={
                    get(slideData, 'caption')
                      ? striptags(get(slideData, 'caption') || '')
                      : this.props.title
                  }
                />
              )}
            </figure>
          ))}
        </Slider>
        <div
          className={`${location}-slideshow__caption`}
          dangerouslySetInnerHTML={{ __html: this.state.currentCaption }}
        />
      </div>
    ) : (
      <div>
        <div
          className="big-card__poster"
          ref={node => {
            this.wrapperDimensions = node;
          }}
        />
        <div className={`${location}-slideshow__caption`} />
      </div>
    );
  }
}

FCSlider.defaultProps = {
  slideShowData: [],
  title: '',
  location: 'header'
};

FCSlider.propTypes = {
  slideShowData: PropTypes.arrayOf(
    PropTypes.shape({
      imgUrl: PropTypes.string,
      caption: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  ),
  title: PropTypes.string,
  location: PropTypes.string
};

export default FCSlider;
