import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';

const RecircByTag = ({ categories, title }) => {
  const [articles, setArticles] = useState(null);
  const [isMobile, setIsMobile] = useState(true);

  const checkIfMobile = () => {
    setIsMobile(window.innerWidth < 640);
  };

  useEffect(() => {
    const sections = ['codesign', 'technology', 'leadership', 'news', 'ideas'];
    const hasSection = sections.includes(get(categories, '[0].slug', false));

    const apikey = 'fastcompany.com';
    const secret = 'Ldqx1NBhAHGqsuzIEaGqfzdmrnojHLYPoCdw4ISeZxU';

    try {
      const url = `https://api.parsely.com/v2/referrers/social/posts?apikey=${apikey}&secret=${secret}${
        hasSection ? `&section=${categories[0].slug}` : ''
      }&pub_date_start=4w&domain=twitter.com&period_start=14d&limit=4`;

      axios(url).then(res => {
        // filter out parsely articles that match
        // the title of the parent post of recirc
        const filteredData = res.data.data.filter(
          parselyPost => parselyPost.title !== title
        );

        // makre sure to only display 3 articles in the recirc
        const finalData = filteredData.slice(0, 3);

        window.recircArticles = finalData; // Make these easily available to the finite scroll components.
        setArticles(finalData);
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        `More Like This failed to get data, will not load component. Reason: ${err}`
      );
    }

    window.addEventListener('resize', () => checkIfMobile());
    checkIfMobile(); // Run once on load.

    return () => {
      window.removeEventListener('resize', () => checkIfMobile());
    };
  }, []);

  // Articles for this module are handled by a data call in src/views/containers/pages/post_page/post_page.js
  if (isMobile || !articles) return false;

  return (
    <aside className="article-recirc-by-tag">
      <div className="header">
        <div className="top-arrow">
          <img
            width="9"
            height="6"
            src="https://images.fastcompany.net/image/upload/v1595605760/fcweb/Path_33_zlkv0h.svg"
            alt=""
          />
        </div>

        <div className="package-name">More Like This</div>
      </div>

      <div className="articles">
        {articles.map(article => (
          <div className="article" key={article.title}>
            <a
              href={`${article.link}&icid=dan902:754:0:editRecirc&evar68=${
                article.link
              }`}
              dangerouslySetInnerHTML={{ __html: article.title }}
            />
          </div>
        ))}
      </div>
    </aside>
  );
};

RecircByTag.propTypes = {
  categories: PropTypes.shape([]),
  title: PropTypes.string
};

RecircByTag.defaultProps = {
  categories: null,
  title: ''
};

export default RecircByTag;
