import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarketoForm from '../../MarketoForm/MarketoForm';

const ArticleFooter = ({ type, lazy }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div
      className={`newsletter__widget newsletter__widget__${type} widget__state--${
        submitted ? 'submitted' : 'init'
      }`}
    >
      <header>
        <img
          alt="Fast Company logo with black background and white lettering"
          className="header__logo"
          src="https://images.fastcompany.net/image/upload/v1663873385/fcweb/fc_bug_j9tlov.svg"
        />
        <div>
          <span className="highlight">Be in the Know.</span>&nbsp;Subscribe to
          Fast Company Newsletters.
        </div>
      </header>
      <MarketoForm
        assetId={1040}
        name="inline-newsletter-form"
        onSubmitHandler={setSubmitted}
        lazy={lazy}
      />
      {submitted && (
        <p className="submission success__message">
          <span className="text-primary">Thank you!</span>&nbsp;Look out for
          your first email.
        </p>
      )}
      <footer>
        <a
          rel="noopener noreferrer"
          href="https://www.mansueto.com/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

ArticleFooter.displayName = 'ArticleFooter';

ArticleFooter.propTypes = {
  type: PropTypes.string.isRequired,
  lazy: PropTypes.bool
};

ArticleFooter.defaultProps = {
  lazy: false
};

export default ArticleFooter;
