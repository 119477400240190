import React, { Component } from 'react';
import uniqBy from 'lodash/uniqBy';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import get from 'lodash/get';
import set from 'lodash/set';
import withRouter from 'react-router/withRouter';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FCLink from '../../../components/fc_link/fc_link';

class MicBar extends Component {
  constructor(props) {
    super(props);
    const { postId, companies, regions, sectors } = this.props;
    const options = [];
    this.handleChange = this.handleChange.bind(this);
    if (companies.length) {
      this.companies = companies.map(c => {
        const rank = get(c, 'customFields.most_innovative_companies.rank');
        if (rank.length === 1) {
          set(c, 'rank', `0${rank}`);
        } else {
          set(c, 'rank', String(rank));
        }
        set(
          c,
          'company',
          get(c, 'customFields.most_innovative_companies.company', '')
        );

        options.push(c);
        return c;
      });
      const cleanedCompanies = uniqBy(this.companies, 'id');
      const middle = findIndex(cleanedCompanies, { id: postId });
      let previous = cleanedCompanies[middle - 1];
      let next = cleanedCompanies[middle + 1];

      if (middle === 0) {
        previous = false;
      }

      if (middle === cleanedCompanies.length - 1) {
        next = false;
      }

      const selected = find(this.companies, { id: postId });

      this.state = {
        selected,
        options,
        previous,
        next
      };
      return;
    }

    if (sectors.length || regions.length) {
      this.sectors = get(props, 'sectors', [])
        .concat(get(props, 'regions', []))
        .map((c, i) => {
          set(c, 'rank', `${i + 1}`);
          set(c, 'isSectorOrRegion', true);
          const name =
            get(c, 'customFields.most_innovative_companies.sector') ||
            get(c, 'customFields.most_innovative_companies.region');
          set(c, 'company', name);

          options.push(c);
          return c;
        });
      const cleanedCompanies = uniqBy(this.sectors, 'id');
      const middle = findIndex(cleanedCompanies, { id: postId });
      let previous = cleanedCompanies[middle - 1];
      let next = cleanedCompanies[middle + 1];

      if (middle === 0) {
        previous = false;
      }

      if (middle === cleanedCompanies.length - 1) {
        next = false;
      }

      const selected = find(this.sectors, { id: postId });

      this.state = {
        selected,
        options,
        previous,
        next
      };
    }
  }

  componentDidMount() {
    this.blurEvent = () => {
      this.select.current.blur();
    };
    document.addEventListener('click', this.blurEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.blurEvent);
  }

  handleChange(event) {
    const findItem = item => item.rank === event.target.value;
    if (this.companies) {
      this.props.nextPage(
        get(this.companies.filter(findItem), '[0].link', '').replace(
          'https://www.fastcompany.com',
          ''
        )
      );
    }
    if (this.sectors) {
      this.props.nextPage(
        get(this.sectors.filter(findItem), '[0].link', '').replace(
          'https://www.fastcompany.com',
          ''
        )
      );
    }
  }

  render() {
    if (!this.state) {
      return null;
    }
    const { selected, options, previous, next } = this.state;
    const arrows = !this.props.companies.length;
    return (
      <div className="mic-bar">
        <strong className="mic-bar__title">
          <a href="/most-innovative-companies/2020" alt="">
            THE WORLD’S MOST INNOVATIVE COMPANIES
          </a>
        </strong>
        <div className="mic-bar__pagination">
          {previous ? (
            <FCLink config={this.props.config} to={previous.link}>
              <button
                className={`mic-bar__button mic-bar__button--back ${
                  arrows ? 'mic-bar__button--arrow' : ''
                }`}
              >
                {get(previous, 'isSectorOrRegion')
                  ? previous.company
                  : previous.rank}
              </button>
            </FCLink>
          ) : null}
          <div className="mic-bar__select">
            <select onChange={this.handleChange}>
              {options.map(item => (
                <option
                  selected={get(item, 'rank') === get(selected, 'rank')}
                  value={get(item, 'rank')}
                >
                  {get(item, 'isSectorOrRegion')
                    ? get(item, 'company')
                    : `${get(item, 'rank')} ${get(item, 'company')}`}
                </option>
              ))}
            </select>
            <div className="mic-bar__selected">
              <strong>
                {get(selected, 'isSectorOrRegion') ? '' : get(selected, 'rank')}
              </strong>{' '}
              {get(selected, 'company')}
            </div>
          </div>
          {next ? (
            <FCLink config={this.props.config} to={next.link}>
              <button
                className={`mic-bar__button mic-bar__button--forward ${
                  arrows ? 'mic-bar__button--arrow' : ''
                }`}
              >
                {get(next, 'isSectorOrRegion') ? next.company : next.rank}
              </button>
            </FCLink>
          ) : null}
        </div>
      </div>
    );
  }
}

MicBar.defaultProps = {
  companies: [],
  regions: [],
  sectors: [],
  config: {},
  postId: '',
  nextPage() {}
};

MicBar.propTypes = {
  config: PropTypes.shape({}),
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  regions: PropTypes.arrayOf(PropTypes.shape({})),
  sectors: PropTypes.arrayOf(PropTypes.shape({})),
  postId: PropTypes.string,
  nextPage: PropTypes.func
};

function mapStateToProps(state = {}, ownProps) {
  return {
    config: state.config || {},
    postId: ownProps.postId,
    companies: ownProps.companies,
    sectors: ownProps.sectors,
    regions: ownProps.regions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nextPage(link) {
      dispatch(push(link));
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MicBar));
