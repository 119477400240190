import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import find from 'lodash/find';
import { uniqBy } from 'lodash';
import Header from './header';
import ByLine from './by_line';
import Article from './article';
import Footer from './footer';
import Social from '../../social/social';
import RecircByTag from '../../recirc_by_tag/recirc_by_tag';
import Live from '../../live/live';
import getByLineAttribution from '../../../../utils/by_line_util';
import MicBar from './mic_bar';
import PackageNav from './package_nav';
import { hasSpotify, handleSpotifyEmbed } from '../utils/embed_spotify';
import PhotoEssaySection from './photo_essay_section';
import StaticBar from './static_bar';

export default class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      social: true
    };
  }

  componentDidMount() {
    const id = get(this.props, 'post.id', '');
    this.postWrapper = document.querySelector(`.post__body[data-id="${id}"]`);
    this.pullquotes = this.postWrapper.querySelectorAll('.perfect-pullquote');

    if (id && this.postWrapper) {
      const article = this.postWrapper.querySelector('.post__article');
      this.hashAnchors = article.querySelectorAll('a[href^="#"]');

      Array.prototype.forEach.call(this.hashAnchors, anchor => {
        anchor.addEventListener('click', this.windowToHash, false);
      });
    }

    if (this.pullquotes.length) {
      this.handleScroll = throttle(this.handleScroll.bind(this), 100);
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.hashAnchors) {
      Array.prototype.forEach.call(this.hashAnchors, anchor => {
        anchor.removeEventListener('click', this.windowToHash, false);
      });
    }

    if (this.pullquotes.length) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll() {
    const id = get(this.props, 'post.id', '');

    const pullquotes = uniqBy(
      document.querySelectorAll(
        `.post__body[data-id="${id}"] .perfect-pullquote`
      ),
      ele => ele.innerHTML
    );
    const social = document.querySelector(
      `.post__body[data-id="${id}"] .post__social-container`
    );

    if (pullquotes.length > 0) {
      for (let i = 0; i < pullquotes.length; i += 1) {
        const quotePos = pullquotes[i].getBoundingClientRect();
        const socialPos = social.getBoundingClientRect();

        const hide =
          quotePos.top - 50 <= socialPos.bottom &&
          quotePos.bottom + 20 >= socialPos.top;

        // when there are multiple pullquotes displaying,
        // we want to make sure to hide the social based on
        // EACH pullquote

        // if the social collides any pullquote and needs to
        // be hidden, break out of the loop. We don't want
        // to keep checking other pullquotes further in the
        // array since they don't matter
        if (hide) {
          this.setState({ social: !hide });
          break;
        }

        // if the social is not colliding with any pullquote
        // show it
        if (!hide) {
          this.setState({ social: !hide });
        }
      }
    }
  }

  windowToHash(event) {
    event.preventDefault();
    const target = document.querySelector(this.getAttribute('href'));

    if (target) {
      window.scrollTo(0, target.offsetTop - 100);
    }
  }

  render() {
    const {
      post,
      advertorial,
      isFirstPost,
      config,
      pageNum,
      isLongPost,
      isVideoPost,
      hasNewsletter,
      related,
      live,
      fullPageAdvertorial,
      postPromoterArticleText,
      hasFCEB,
      canRenderInfiniteScroll,
      photoEssaySections,
      photoEssayAdFrequency,
      supertags,
      specialRedesignArticle
    } = this.props;
    const postId = get(post, 'id');
    const { tags, content: article, layout } = post;
    const emptyContent = !get(article, 'length');
    const bylineAttribution = getByLineAttribution(tags);
    const postFiniteLive =
      !isFirstPost &&
      get(post, 'tags', []).filter(tag => tag.name.indexOf('live:') > -1)
        .length === 1;
    const hasLivePosts = Boolean(get(post, 'live.posts.length', false));
    const hidePoster = find(tags, tag => tag.slug === 'no-poster');
    const isMic2020SectorPost = find(get(post, 'tags'), {
      name: 'Most Innovative Companies 2020 Sectors'
    });

    const isMic2020RegionPost = find(get(post, 'tags'), {
      name: 'Most Innovative Companies 2020 Regions'
    });

    const isMic2020RegionPostOrSectorPost =
      isMic2020RegionPost || isMic2020SectorPost;

    const isMic2021SectorPost = find(get(post, 'tags'), {
      name: 'mic 2021 sector'
    });

    const isMic2020CompanyPost = find(get(post, 'tags'), {
      name: 'Most Innovative Companies 2020'
    });
    const isWci2020ComponanyPost = find(get(post, 'tags'), {
      name: 'World Changing Ideas Awards 2020'
    });
    const isMcp2020CompanyPost = find(get(post, 'tags'), {
      name: 'Most Creative People 2020'
    });
    const isIbd2020ComponanyPost = find(get(post, 'tags'), {
      name: 'innovation by design 2020'
    });

    const calloutBox =
      get(post, 'customFields.show_callout_box', false) === true;
    // console.log('callout box', calloutBox, get(post, 'customFields.show_callout_box', false) )

    const calloutHeader = get(post, 'customFields.callout_header', false);
    // console.log('callout header', calloutHeader, get(post, 'customFields.callout_header', false) )

    const calloutBody = get(post, 'customFields.callout_body_copy', false);
    // console.log('callout header', calloutBody, get(post, 'customFields.callout_body_copy', false) )

    let navData = {};

    const showPackageNav =
      isWci2020ComponanyPost || isMcp2020CompanyPost || isIbd2020ComponanyPost;

    if (showPackageNav) {
      if (isWci2020ComponanyPost) {
        navData = {
          name: 'World Changing Ideas',
          url: '/world-changing-ideas-2020',
          hardCoded: true
        };
      } else if (isMcp2020CompanyPost) {
        navData = {
          name: 'Most Creative People',
          url: '/most-creative-people/2020',
          hardCoded: false,
          articleId: '90523229'
        };
      } else if (isIbd2020ComponanyPost) {
        navData = {
          name: 'Innovation By Design',
          url: '/innovation-by-design/2020',
          hardCoded: true
        };
      }
    }

    const micCompany = isMic2020CompanyPost ? (
      <MicBar companies={this.props.mic2020Companies} postId={postId} />
    ) : null;

    const micSector = isMic2020RegionPostOrSectorPost ? (
      <MicBar
        sectors={this.props.mic2020Sectors}
        regions={this.props.mic2020Regions}
        companies={false}
        postId={postId}
      />
    ) : null;

    const mic2021Sector = isMic2021SectorPost ? (
      <StaticBar postId={postId} />
    ) : null;

    let micbar = micSector || micCompany || null;
    let micstaticbar = mic2021Sector || null;

    if (!config.featureFlags.mic2020) {
      micbar = null;
    }

    if (!config.featureFlags.mic2021staticbar) {
      micstaticbar = null;
    }

    const PhotoEssaySections = photoEssaySections
      ? this.props.photoEssaySections.map((section, index, arr) => (
          <PhotoEssaySection
            {...section}
            index={index}
            lastSection={index === arr.length - 1}
            adFrequency={photoEssayAdFrequency}
          />
        ))
      : null;
    // Dont show recirc on _custom tagged pages
    // console.log('body tags', tags);
    let showRecirc;
    tags.forEach(obj => {
      if (
        obj.name === '_custom' ||
        obj.name === 'custom' ||
        obj.name === 'premium'
      ) {
        // console.log('custom:::', obj);
        showRecirc = false;
      }
    });

    return (
      <div className="post__body" data-id={post.id} data-slug={post.slug}>
        {layout === 'redesign' && !hidePoster ? (
          <Header
            tags={tags}
            advertorial={advertorial}
            post={post}
            isFirstPost={isFirstPost}
            isLongPost={isLongPost}
            isVideoPost={isVideoPost}
            related={related}
            live={live || postFiniteLive}
            config={config}
            supertags={supertags}
            fullPageAdvertorial={advertorial}
            hasFCEB={hasFCEB}
          />
        ) : null}

        {layout === 'long' && !hidePoster ? (
          <Header
            tags={tags}
            advertorial={advertorial}
            post={post}
            isFirstPost={isFirstPost}
            isLongPost={isLongPost}
            isVideoPost={isVideoPost}
            related={related}
            live={live || postFiniteLive}
            config={config}
            supertags={supertags}
            fullPageAdvertorial={advertorial}
            hasFCEB={hasFCEB}
          />
        ) : null}

        {isVideoPost ? (
          <div>
            <Social
              enableSticky={this.props.isDesktop && isLongPost}
              empty={emptyContent}
              text={encodeURIComponent(get(post, 'title') || '')}
              uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
              modifier="post-video"
              bottomBoundary={`#social--${post.id}`}
              encapsulateId={`social--${post.id}`}
              tags={this.props.post.tags}
              post={this.props.post}
              {...this.props}
            />

            {showRecirc !== false ? <RecircByTag {...this.props.post} /> : null}
          </div>
        ) : (
          <div className={`post__container post__container--${post.layout}`}>
            <div
              className="post__left-rail"
              style={{
                transition: 'opacity 250ms',
                opacity: this.state.social ? 1 : 0
              }}
            >
              <div className="post__social-container">
                <Social
                  enableSticky={this.props.isDesktop && isLongPost}
                  empty={emptyContent}
                  topValue={fullPageAdvertorial ? 160 : 111}
                  text={encodeURIComponent(get(post, 'title') || '')}
                  uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
                  modifier="post"
                  bottomBoundary={`#social--${post.id}`}
                  encapsulateId={`social--${post.id}`}
                  // active={this.state.social}
                  tags={this.props.post.tags}
                  post={this.props.post}
                  {...this.props}
                />

                {/* {showRecirc !== false ? (
                  <RecircByTag {...this.props.post} />
                ) : null} */}
              </div>
            </div>
            <div className="post__content">
              {layout === 'short' && !hidePoster ? (
                <Header
                  tags={tags}
                  advertorial={advertorial}
                  post={post}
                  isFirstPost={isFirstPost}
                  isLongPost={isLongPost}
                  isVideoPost={isVideoPost}
                  related={related}
                  live={live || postFiniteLive}
                  config={config}
                />
              ) : null}

              {hasSpotify(postId) ? handleSpotifyEmbed(postId) : null}

              {!hidePoster && !this.props.specialRedesignArticle ? (
                <ByLine
                  post={post}
                  bylineAttribution={bylineAttribution}
                  live={hasLivePosts}
                />
              ) : null}

              {micbar}
              {micstaticbar}

              {showPackageNav && (
                <PackageNav url={post.link} navData={navData} />
              )}

              <Article
                config={config}
                advertorial={advertorial}
                post={post}
                pageNum={pageNum}
                isShortPost={!isLongPost}
                isFirstPost={isFirstPost}
                specialRedesignArticle={this.props.specialRedesignArticle}
                getAdhesiveHeight={this.props.getAdhesiveHeight}
                insert={this.props.insert}
              />

              {PhotoEssaySections}

              {postFiniteLive ? (
                <a
                  className="post__live-link"
                  href={`/${post.id}/${post.slug}`}
                >
                  Follow Our Live Coverage
                </a>
              ) : null}
              {calloutBox ? (
                <div className="fc_callout_box">
                  <p className="fc_callout_header">{calloutHeader}</p>
                  <div
                    className="fc_callout_body"
                    dangerouslySetInnerHTML={{ __html: calloutBody }}
                  />
                </div>
              ) : null}
              {!live &&
                !hasLivePosts && (
                  <Footer
                    post={post}
                    bylineAttribution={bylineAttribution}
                    isLongPost={isLongPost}
                    hasNewsletter={hasNewsletter}
                    config={config}
                    promoText={postPromoterArticleText}
                    hasFCEB={hasFCEB}
                    canRenderInfiniteScroll={canRenderInfiniteScroll}
                    isFirstPost={isFirstPost}
                    specialRedesignArticle={specialRedesignArticle}
                  />
                )}

              {(isFirstPost && live) || hasLivePosts ? (
                <Live parentPost={post} live={post.live} config={config} />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Body.defaultProps = {
  isDesktop: false,
  hasNewsletter: false,
  isVideoPost: false,
  isLongPost: false,
  related: [],
  fullPageAdvertorial: false,
  mic2020Companies: [],
  mic2020Regions: [],
  mic2020Sectors: [],
  postPromoterArticleText: '',
  hasFCEB: false,
  canRenderInfiniteScroll: false,
  photoEssaySections: false,
  photoEssayAdFrequency: 1,
  supertags: [],
  specialRedesignArticle: false,
  getAdhesiveHeight: () => 0,
  insert: false
};

Body.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tags: PropTypes.oneOfType([PropTypes.array])
  }).isRequired,
  advertorial: PropTypes.bool.isRequired,
  isFirstPost: PropTypes.bool.isRequired,
  config: PropTypes.shape({}).isRequired,
  isDesktop: PropTypes.bool,
  pageNum: PropTypes.number.isRequired,
  isLongPost: PropTypes.bool,
  hasNewsletter: PropTypes.bool,
  isVideoPost: PropTypes.bool,
  related: PropTypes.arrayOf(PropTypes.shape({})),
  live: PropTypes.bool.isRequired,
  fullPageAdvertorial: PropTypes.bool,
  mic2020Companies: PropTypes.arrayOf(PropTypes.shape({})),
  mic2020Regions: PropTypes.arrayOf(PropTypes.shape({})),
  mic2020Sectors: PropTypes.arrayOf(PropTypes.shape({})),
  postPromoterArticleText: PropTypes.string,
  hasFCEB: PropTypes.bool,
  canRenderInfiniteScroll: PropTypes.bool,
  photoEssaySections: PropTypes.arrayOf(PropTypes.shape({})),
  photoEssayAdFrequency: PropTypes.number,
  supertags: PropTypes.arrayOf(PropTypes.string),
  specialRedesignArticle: PropTypes.bool,
  getAdhesiveHeight: PropTypes.func,
  insert: PropTypes.bool
};
