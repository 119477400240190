import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarketoForm from '../../../MarketoForm/MarketoForm';
import ModernCEOLogo from './ModernCEOLogo';

const ModernCEO = ({ type, lazy }) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div
      className={`newsletter__widget newsletter__widget__${type} widget__state--${
        submitted ? 'submitted' : 'init'
      }`}
    >
      {submitted ? (
        <React.Fragment>
          <header>
            <ModernCEOLogo />
          </header>
          <p className="submission success__message">
            <span className="text-primary">Thank you!</span>&nbsp;Look out for
            your first email.
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <header>
            <ModernCEOLogo />
            <p>
              A refreshed look at leadership from the desk of CEO and chief
              content officer Stephanie Mehta
            </p>
          </header>
          <MarketoForm
            assetId={1192}
            name="inline-newsletter-form"
            onSubmitHandler={setSubmitted}
            lazy={lazy}
          />
        </React.Fragment>
      )}
      <footer>
        <a
          rel="noopener noreferrer"
          href="https://www.mansueto.com/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

ModernCEO.displayName = 'ModernCEO';

ModernCEO.propTypes = {
  type: PropTypes.string.isRequired,
  lazy: PropTypes.bool
};

ModernCEO.defaultProps = {
  lazy: false
};

export default ModernCEO;
